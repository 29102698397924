import { useMemo } from 'react';
import classes from './EmptyItems.module.scss';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components/Button';
import { RecommendedService } from 'containers/RecommendedService/';
import { useTranslation } from 'react-i18next';
import { useMarketPlaceStore } from 'contexts/marketPlaceContext';
import clsx from 'clsx';
import { EmptyItemsProps } from './EmptyItems.props';

export const EmptyItems = ({
  isBackground = false,
  title,
  description,
}: EmptyItemsProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const marketPlaceStore = useMarketPlaceStore();

  const allProducts = marketPlaceStore.services;

  const activeIds = useMemo(
    () =>
      allProducts
        ?.filter(({ is_active, is_favorite }: any) => is_active && !is_favorite)
        .map(({ id }: any) => id)
        .sort(() => Math.random() - 0.5)
        .slice(0, 5),
    [allProducts]
  );

  return (
    <div className={classes.wrapper}>
      <div
        className={clsx(classes.container, isBackground && 'empty-background')}
      >
        <div className={classes.inner}>
          <div className={classes.info}>
            <h2 className={classes.title}>{t(title)}</h2>
            <p className={classes.text}>{t(description)}</p>
            <Button
              theme="primary"
              className={classes.button}
              onClick={() => navigate('/solutions')}
            >
              {t('Back to Solutions')}
            </Button>
          </div>
        </div>
      </div>

      <div className={classes.recommended}>
        <RecommendedService
          title={t('Others usually choose')}
          // ids={activeIds}
        />
      </div>
    </div>
  );
};
