import React, { FC, useRef, useState, useEffect } from 'react';
import { Input } from 'components/Input';
import classes from './SearchComponent.module.scss';
import { SearchGradientIcon } from 'components/Icons/SearchGradientIcon';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useEventListener } from 'hooks/useEventListener';
import Search from 'services/search';
import { ShowNotification } from 'tools/showNotification';
import { ClearIcon } from 'components/Icons/ClearIcon';
import i18n from 'i18n/i18n';

interface Props {
  setOpenSearch: (val: boolean) => void;
  disabled?: boolean;
}

export const SearchComponent: FC<Props> = ({ setOpenSearch, disabled }) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [searchSolutions, setSearchSolutions] = useState<any>([]);
  const [searchProjects, setSearchProjects] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [focus, setFocus] = useState(false);
  const ref = useRef<any>(null);
  const navigate = useNavigate();
  const [responsible, setResponsible] = useState(false);

  const getSearchResults = async () => {
    try {
      setResponsible(false);

      // const searchQueries =
      //   (search.trim() !== '&' && search.split('&').map((q) => q.trim())) || [];

      // Кодируем символ '&' в строке поиска
      const encodedSearch = search.replace(/&/g, '%26');
      const searchQueries = encodedSearch.split('&').map((q) => q.trim());
      let combinedSolutions: any = [];
      let combinedProjects: any = [];

      if (searchQueries?.length) {
        for (const query of searchQueries) {
          const [responseSolutions, responseProjects] = await Promise.all([
            query && Search.getSearchResults('solutions', query),
            query && Search.getSearchResults('projects', query),
          ]);

          if (responseSolutions) {
            combinedSolutions = [
              ...combinedSolutions,
              ...responseSolutions?.data?.results,
            ];
          }

          if (responseProjects) {
            combinedProjects = [
              ...combinedProjects,
              ...responseProjects?.data?.results,
            ];
          }
        }
      } else {
        const [responseSolutions, responseProjects] = await Promise.all([
          Search.getFastSearchResults(search.trim()),
          Search.getFastSearchResults(search.trim()),
        ]);

        if (responseSolutions) {
          combinedSolutions = responseSolutions?.data?.results;
        }

        if (responseProjects) {
          combinedProjects = responseProjects?.data?.results;
        }
      }

      setSearchSolutions(combinedSolutions);
      setSearchProjects(combinedProjects);

      if (combinedSolutions.length || combinedProjects.length) {
        setResponsible(true);
      }
    } catch (error) {
      ShowNotification({
        type: 'error',
        children: t('Unknown error. Please, try again later'),
      });
    }
  };

  useEffect(() => {
    if (search) {
      getSearchResults();
    }
  }, [search]);

  const handleClick = (e: any) => {
    if (ref.current && !ref.current?.contains(e.target) && !focus) {
      setOpen(false);
    }
  };
  useEventListener('click', handleClick);

  const customText = (text: string) => {
    const trimmedText = text.trim();
    const searchQueries =
      (search !== '&' && search.split('&').map((q) => q.trim())) || [];
    const parts: any[] = [];
    let lastIndex = 0;

    if (searchQueries?.length) {
      searchQueries.forEach((query) => {
        const queryIndex = trimmedText
          .toLocaleUpperCase()
          .indexOf(query.toLocaleUpperCase(), lastIndex);

        if (queryIndex !== -1) {
          if (queryIndex > lastIndex) {
            parts.push(trimmedText.slice(lastIndex, queryIndex));
          }
          parts.push(
            <span key={queryIndex} className={classes['search__search-text']}>
              {trimmedText.slice(queryIndex, queryIndex + query.length)}
            </span>
          );
          lastIndex = queryIndex + query.length;
        }
      });
    } else {
      const queryIndex = trimmedText
        .toLocaleUpperCase()
        .indexOf(search.toLocaleUpperCase(), lastIndex);

      if (queryIndex !== -1) {
        if (queryIndex > lastIndex) {
          parts.push(trimmedText.slice(lastIndex, queryIndex));
        }
        parts.push(
          <span key={queryIndex} className={classes['search__search-text']}>
            {trimmedText.slice(queryIndex, queryIndex + search.length)}
          </span>
        );
        lastIndex = queryIndex + search.length;
      }
    }

    if (lastIndex < trimmedText.length) {
      parts.push(trimmedText.slice(lastIndex));
    }

    return <div>{parts}</div>;
  };

  const searchNavigate = (e: any) => {
    if (e.key === 'Enter' && responsible && search) {
      navigate(
        `/search-results#from=${encodeURIComponent(
          window.location.pathname
        )}&search=${encodeURIComponent(search)}`
      );
      setOpen(false);
      setOpenSearch(false);
    }
  };

  const clearSearch = () => {
    setSearch('');
    getSearchResults();
    setOpen(false);
    setResponsible(false);
  };

  return (
    <div className={classes['search']}>
      <div
        className={classes['search__background']}
        onClick={() => setOpenSearch(false)}
      ></div>
      <div className={classes['search__container']}>
        <div ref={ref} className={classes['search__wrapper']}>
          <Input
            value={search}
            onChange={setSearch}
            type="text"
            className={clsx(classes['search__input'])}
            placeholder={t('Search')}
            disabled={disabled}
            onFocus={() => {
              setOpen(true);
              setFocus(true);
            }}
            onBlur={() => setFocus(false)}
            onKeyDown={(e) => searchNavigate(e)}
          />
          <div className={classes['search__btn']}>
            <SearchGradientIcon />
          </div>
          {search && (
            <div
              className={classes['search__clear']}
              onClick={() => clearSearch()}
            >
              <ClearIcon />
            </div>
          )}
          {search && open && (
            <div className={classes['search__dropdown']}>
              {searchSolutions.length ? (
                <>
                  <span className={classes['search__dropdown-title']}>
                    {t('Solutions')}
                  </span>
                  <ul className={classes['search__dropdown-list']}>
                    {searchSolutions?.map((item: any) => (
                      <li
                        className={classes['search__dropdown-item']}
                        key={item.slug}
                      >
                        <Link
                          to={`/solutions/${item.slug}`}
                          onClick={() => {
                            setSearch('');
                            setSearchSolutions([]);
                          }}
                        >
                          {customText(
                            item[`name_${i18n.language}`] ?? item.name
                          )}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </>
              ) : null}
              {searchProjects.length ? (
                <>
                  <span className={classes['search__dropdown-title']}>
                    {t('Projects')}
                  </span>
                  <ul className={classes['search__dropdown-list']}>
                    {searchProjects?.map((item: any) => (
                      <li
                        className={classes['search__dropdown-item']}
                        key={item.id}
                      >
                        <Link
                          to={`/projects/${item.id}`}
                          onClick={() => {
                            setSearch('');
                            setSearchSolutions([]);
                          }}
                        >
                          {customText(
                            item[`name_${i18n.language}`] ?? item.name
                          )}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </>
              ) : null}
              {searchSolutions.length || searchProjects.length ? (
                <Link
                  to={{
                    pathname: '/search-results',
                    hash: `from=${encodeURIComponent(
                      window.location.pathname
                    )}&search=${encodeURIComponent(search)}`,
                  }}
                  state={{ search: search }}
                  className={classes['search__see-all']}
                  onClick={() => {
                    setOpen(false);
                    setOpenSearch(false);
                  }}
                >
                  {t('See all')}
                </Link>
              ) : (
                <p className={classes['search__not-found']}>
                  {t('No results found for')}
                  <span>
                    {' «'}
                    {search}
                    {'»'}
                  </span>{' '}
                  {'. '} {t('Please check the spelling or use different words')}
                </p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
