import i18n from 'i18n/i18n';

export const formatBalanceForLocale = (
  balanceValue: number,
  currency: string,
  currentCurrencySymbol?: string
) => {
  const lang = i18n.language;

  // When the language is en and the currency selected is IDR or any other - then instead of the symbol we substitute char_code since the language does not match the char_code
  if (currentCurrencySymbol && lang !== 'ru')
    return `${currentCurrencySymbol}${new Intl.NumberFormat('fr-FR', {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(balanceValue ?? 0)}`;

  // We do this check because in some cases "PERC" may come in char_code and we need to output the % sign
  if (currency === 'PERC')
    return `${new Intl.NumberFormat(lang, {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(balanceValue ?? 0)}%`;

  // Handle Empty value in value unit cases
  if (currency === 'NONE')
    return new Intl.NumberFormat(lang, {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(balanceValue ?? 0);

  // Delete the block below after the CUR in the data from the backend is removed
  if (currency === 'CUR') {
    return new Intl.NumberFormat(lang, {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(balanceValue ?? 0);
  }
  //_________________

  return new Intl.NumberFormat(lang, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(balanceValue ?? 0);
};
