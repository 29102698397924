import * as yup from 'yup';
import { t } from 'i18next';
import { isValidPhoneNumber } from 'libphonenumber-js';

const onlyLetters = /^\D*$/;
export const numbersCheckReg = /\d+/;
export const symbolsCheckReg = /[~!@#$%^&*()_+<>?￥¿¡·॰।]/;
export const urlCheckReg =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
const noSpacesCheckReg = /^[^\s]*$/;
export const lettersCheckReg = /[A-Za-z]/;

export const personalSchema = yup.object().shape({
  fullName: yup
    .string()
    .matches(onlyLetters)
    .required(t('This field is required')),
  email: yup.string().email(t('Invalid Email')),
  phone: yup.string().required(t('This field is required')),
});

export const passwordSchema = (errorMessage: string, t: any) =>
  yup.object().shape({
    oldPass: yup.string().min(1).required(t('This field is required')),
    newPass: yup
      .string()
      .min(8)
      .matches(symbolsCheckReg)
      .matches(numbersCheckReg)
      .matches(noSpacesCheckReg, t('Password must not contain spaces'))
      .matches(lettersCheckReg, t('Password must contain at least 1 letter'))
      .required(t('This field is required')),
    newPassConfirmation: yup
      .string()
      .oneOf([yup.ref('newPass'), undefined], errorMessage)
      .required(t('This field is required')),
  });

export const newPasswordSchema = (errorMessage: string) =>
  yup.object().shape({
    newPass: yup
      .string()
      .min(8)
      .matches(numbersCheckReg)
      .required(t('This field is required')),
    newPassConfirmation: yup
      .string()
      .oneOf([yup.ref('newPass'), undefined], errorMessage)
      .required(t('This field is required')),
  });

export const registrationStepOneSchema = (t: any) =>
  yup.object().shape({
    fullName: yup
      .string()
      .matches(onlyLetters, t('Field contains invalid characters'))
      .required(t('This field is required')),
    email: yup
      .string()
      .email(t('Invalid Email'))
      .required(t('This field is required')),
    phone: yup
      .string()
      .test(
        'isValidPhoneNumber',
        t('Phone number is incorrect'),
        (value: any) => isValidPhoneNumber(`+${value}`)
      )
      .required(t('This field is required')),
    password: yup
      .string()
      .min(8, t('Password must be at least 8 characters long'))
      .matches(
        symbolsCheckReg,
        t('Password must contain at least one special character')
      )
      .matches(numbersCheckReg, t('Password must contain at least one number'))
      .matches(lettersCheckReg, t('Password must contain at least 1 letter'))
      .matches(noSpacesCheckReg, t('Password must not contain spaces'))
      .required(t('This field is required')),
    kid_token: yup.string(),
    iAgree: yup
      .boolean()
      .oneOf([true], t('You must agree to the terms and conditions'))
      .required(t('This field is required')),
  });

export const registrationStepTwoSchema = (t: any) =>
  yup.object().shape({
    companyName: yup.string().required(t('This field is required')),
    url: yup
      .string()
      .matches(urlCheckReg, t('Invalid link'))
      .required(t('This field is required')),
    size: yup.string(),
    industry: yup.string(),
    captcha:
      process.env.REACT_APP_DISABLE_RECAPCHA === 'false'
        ? yup.string().required(t('This field is required'))
        : yup.string(),
  });

export const registrationRuStepOneSchema = (t: any) =>
  yup.object().shape({
    kid_token: yup.string(),
    iAgree: yup
      .boolean()
      .oneOf([true], t('You must agree to the terms and conditions'))
      .required(t('This field is required')),
    companyName: yup
      .string()
      .trim()
      .max(200, t('Company name must be at most 200 characters'))
      .required(t('This field is required')),
    url: yup
      .string()
      .matches(urlCheckReg, t('Invalid link'))
      .required(t('This field is required')),
    size: yup.string(),
    industry: yup.string(),
  });

export const PASS_MODE = {
  OLD: 'oldPass',
  NEW: 'newPass',
  CONFIRM: 'newPassConfirmation',
};

export interface RegistrationStepOneProps {
  fullName: string;
  email: string;
  phone: string;
  password: string;
  kid_token: string;
  iAgree: boolean;
}

export interface RegistrationStepTwoProps extends RegistrationStepOneProps {
  companyName: string;
  url: string;
  size: string;
  industry: string;
  captcha: string;
}

export interface RegistrationRuStepOneProps {
  companyName: string;
  url: string;
  size: string;
  industry: string;
  kid_token: string;
  iAgree: boolean;
}
