import React, { FC, useEffect } from 'react';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { AccountContainer } from 'containers/Account';
import { useNavigate } from 'react-router-dom';
import '../styles/main.scss';
import { RUSSIAN_LOCALE } from 'utils';

interface Props {
  section: string;
}

// todo add localization feature
export const Account: FC<Props> = ({ section }) => {
  const navigate = useNavigate();

  const { isMediaTablet } = useWindowWidth();

  useEffect(() => {
    if (!section) {
      !isMediaTablet && navigate('personal');
    }
    if (section === 'change-password' && RUSSIAN_LOCALE) navigate('/');
  }, [isMediaTablet]);

  return <AccountContainer section={section} />;
};
