import { ProjectsDetailContainer } from 'containers/ProjectsDetail';
import { FC } from 'react';
import '../styles/main.scss';
import { useUserStore } from '../contexts/UserContext';
import { NotFoundPage } from './NotFoundPage';

// todo add localization feature
export const ProjectsDetail: FC = () => {
  const userStore = useUserStore();

  return userStore?.isProjectError ? (
    <NotFoundPage />
  ) : (
    <ProjectsDetailContainer />
  );
};
