import { useState, useEffect } from 'react';
import classes from './Switch.module.scss';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { ShapeGradientIcon } from 'components/Icons/ShapeGradientIcon';
import { WalletGradientIcon } from 'components/Icons/WalletGradientIcon';
import { LockGradientIcon } from 'components/Icons/LockGradientIcon';
import { ArrowDownIcon } from 'components/Icons/ArrowDownIcon';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { routers } from 'constants/routers';
import { useTranslation } from 'react-i18next';
import { RUSSIAN_LOCALE } from 'utils';
import { LeftArrowIcon } from 'components/Icons/LeftArrowIcon';

interface Props {
  className: string;
  section: string;
  setBusinessTypes: (val: any) => void;
  setCompanySizes: (val: any) => void;
  currentStep?: number;
}

export const Switch = ({
  className: auditionalClassname,
  section,
  setBusinessTypes,
  setCompanySizes,
  currentStep,
}: Props) => {
  const { isMediaTablet, isSmallLaptop } = useWindowWidth();
  const { origin, pathname } = window.location;
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const isLinkAccount =
    pathname === `${routers.personal}` ||
    pathname === `${routers.business}` ||
    pathname === `${routers.changePassword}`;

  useEffect(() => {
    if (isMediaTablet) {
      if (
        pathname !== `${routers.personal}` &&
        pathname !== `${routers.business}` &&
        pathname !== `${routers.changePassword}`
      ) {
        setOpen(false);
      } else {
        setOpen(true);
      }
    }
  }, [pathname]);

  return (
    <div
      className={clsx(
        classes.wrapper,
        auditionalClassname && classes[auditionalClassname]
      )}
    >
      {isMediaTablet && (
        <div className={classes.mobileTitle}>{t('Account')}</div>
      )}

      <div className={classes.list}>
        <NavLink
          to={section && !isSmallLaptop ? '/account' : '/account/personal'}
          className={clsx(
            classes.link,
            isLinkAccount && classes.isHidden,
            (currentStep === 1 || section === 'personal') && classes.active
          )}
        >
          <div className={clsx(classes.icon, classes.iconShape)}>
            <ShapeGradientIcon />
          </div>
          <div className={classes.back}>
            <ArrowDownIcon fill={'#898989'} width={'16px'} height={'8px'} />
          </div>
          <div className={classes.name}>{t('Personal information')}</div>
        </NavLink>

        <NavLink
          to={section && !isSmallLaptop ? '/account' : '/account/business'}
          className={clsx(
            classes.link,
            isLinkAccount && classes.isHidden,
            (currentStep === 2 || section === 'business') && classes.active
          )}
        >
          <div className={classes.icon}>
            <WalletGradientIcon />
          </div>
          <div className={classes.back}>
            <ArrowDownIcon fill={'#898989'} width={'16px'} height={'8px'} />
          </div>
          <div className={classes.name}>{t('Business information')}</div>
        </NavLink>

        {!RUSSIAN_LOCALE && (
          <NavLink
            to={
              section && !isSmallLaptop
                ? '/account'
                : '/account/change-password'
            }
            className={clsx(
              classes.link,
              isLinkAccount && classes.isHidden,
              (currentStep === 3 || section === 'change-password') &&
                classes.active
            )}
          >
            <div className={classes.icon}>
              <LockGradientIcon />
            </div>
            <div className={classes.back}>
              <ArrowDownIcon fill={'#898989'} width={'16px'} height={'8px'} />
            </div>
            <div className={classes.name}>{t('Change password')}</div>
          </NavLink>
        )}
      </div>

      {!isSmallLaptop && isLinkAccount && (
        <NavLink to={'/account'} className={classes['link-back']}>
          <LeftArrowIcon className={classes['link-back__arrow']} />
          <span className={classes.backTo}>{t('Back to Account')}</span>
        </NavLink>
      )}
    </div>
  );
};
