import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import clsx from 'classnames';

import classes from './Filters.module.scss';

interface Props {
  all: number;
  active: number;
  completed: number;
  paused: number;
  cancelled: number;
  filter: 'all' | 'active' | 'completed' | 'paused' | 'cancelled';
  setFilter: (
    val: 'all' | 'active' | 'completed' | 'paused' | 'cancelled'
  ) => void;
}

export const Filters: FC<Props> = ({
  all,
  active,
  completed,
  paused,
  cancelled,
  filter,
  setFilter,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <div
        role="button"
        tabIndex={-1}
        onClick={() => {
          if (all !== 0) {
            setFilter('all');
          }
        }}
        className={clsx(classes.filter, filter === 'all' && classes.active)}
      >
        {t('All')}
        <div className={clsx(classes.count, all > 9 && classes.countMedium)}>
          {all}
        </div>
      </div>
      <div
        role="button"
        tabIndex={-1}
        onClick={() => {
          if (active !== 0) {
            setFilter('active');
          }
        }}
        className={clsx(
          classes.filter,
          filter === 'active' && classes.active,
          active === 0 && classes.disabled
        )}
      >
        {t('Active')}
        <div className={clsx(classes.count, active > 9 && classes.countMedium)}>
          {active}
        </div>
      </div>
      <div
        role="button"
        tabIndex={-1}
        onClick={() => {
          if (completed !== 0) {
            setFilter('completed');
          }
        }}
        className={clsx(
          classes.filter,
          filter === 'completed' && classes.active,
          completed === 0 && classes.disabled
        )}
      >
        {t('Completed')}
        <div
          className={clsx(classes.count, completed > 9 && classes.countMedium)}
        >
          {completed}
        </div>
      </div>
      <div
        role="button"
        tabIndex={-1}
        onClick={() => {
          if (paused !== 0) {
            setFilter('paused');
          }
        }}
        className={clsx(
          classes.filter,
          filter === 'paused' && classes.active,
          paused === 0 && classes.disabled
        )}
      >
        {t('Paused')}
        <div className={clsx(classes.count, paused > 9 && classes.countMedium)}>
          {paused}
        </div>
      </div>
      <div
        role="button"
        tabIndex={-1}
        onClick={() => {
          if (cancelled !== 0) {
            setFilter('cancelled');
          }
        }}
        className={clsx(
          classes.filter,
          filter === 'cancelled' && classes.active,
          cancelled === 0 && classes.disabled
        )}
      >
        {t('Cancelled')}
        <div
          className={clsx(classes.count, cancelled > 9 && classes.countMedium)}
        >
          {cancelled}
        </div>
      </div>
    </div>
  );
};
