import { FC, useEffect } from 'react';
import Footer from 'components/Footer';
import { Header } from 'components/Header';
import { useNavigate, useOutlet, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { useUserStore } from 'contexts/UserContext';
import { PageLoader } from './PageLoader';
import { observer } from 'mobx-react-lite';
import ErrorBoundary from './ErrorBoundary';
import { useTranslation } from 'react-i18next';
import { useAuthStore } from '../contexts/AuthContext';

interface Props {
  children?: any;
}

export const BaseTemplate: FC<Props> = observer(() => {
  const outlet = useOutlet();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const userStore = useUserStore();

  const { isDataLoading, isPermissionError, isLocaleError } = userStore || {};
  const { isServerError } = useAuthStore() || {};

  useEffect(() => {
    if (isServerError) {
      navigate('/error', { replace: true });
      sessionStorage.setItem('prevPath', location.pathname || 'error');
    } else {
      const prevPath = sessionStorage.getItem('prevPath');

      if (location.pathname === '/login') {
        navigate(`${process.env.REACT_APP_LOGIN_SUCCESS_URL}`, {
          replace: true,
        });
        return;
      } else {
        if (prevPath) {
          navigate(
            `${
              prevPath !== location.pathname
                ? prevPath
                : process.env.REACT_APP_LOGIN_SUCCESS_URL
            }`,
            { replace: true }
          );
        }
      }
    }
  }, [isServerError]);

  const isLoader =
    isDataLoading && !isServerError && !isPermissionError && !isLocaleError;
  const showHeader = userStore?.isComplete && !isLocaleError;
  const showFooter = !isServerError && !isPermissionError && !isLocaleError;

  const renderBaseTemplate = (
    <section
      className={clsx(
        'app__section',
        location.pathname === '/projects' ||
          location.pathname === '/finances' ||
          !userStore?.user?.currency
          ? 'app__section--gradient'
          : null
      )}
    >
      <ErrorBoundary t={t} navigate={navigate} isServerError={isServerError}>
        {showHeader && <Header />}
        <div className="layout-container">
          {!isLoader && outlet}
          {showFooter && <Footer />}
        </div>
      </ErrorBoundary>
    </section>
  );

  return isLoader ? (
    <PageLoader children={renderBaseTemplate} />
  ) : (
    renderBaseTemplate
  );
});
