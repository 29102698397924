import React, { FC } from 'react';
import UiIcon from '../Icon';
import { EmptyIcon } from 'components/Icons/EmptyIcon';

import classes from './EmptyProducts.module.scss';

interface EmptyProductsProps {
  title?: string;
  iconName?: string;
  noIcon?: boolean;
  iconOptions?: any;
  bottomText?: string;
}

const EmptyProducts: FC<EmptyProductsProps> = ({
  title,
  iconName = 'EmptyIcon',
  noIcon = false,
  iconOptions,
  bottomText,
}) => (
  <div className={classes['empty__container']}>
    {title && <span className={classes['empty__title']}>{title}</span>}
    {!noIcon && <EmptyIcon />}
    {bottomText && (
      <span className={classes['empty__bottom-text']}>{bottomText}</span>
    )}
  </div>
);

export default EmptyProducts;
