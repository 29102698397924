import React, { FC } from 'react';
import { NotFoundPageContainer } from 'components/NotFoundPageContainer';
import { TitleAndDescription } from 'components/TitleAndDescription/TitleAndDescription';
import '../styles/main.scss';

export const NotFoundPage: FC = () => (
  <>
    <TitleAndDescription pageName="404" />
    <NotFoundPageContainer />
  </>
);
