import { TitleAndDescription } from 'components/TitleAndDescription/TitleAndDescription';
import classes from './Disclaimer.module.scss';
import { PurpleArrowLeftIcon } from 'components/Icons/PurpleArrowLeftIcon';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RUSSIAN_LOCALE } from 'utils';

export const DisclaimerContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const goBack = () => {
    if (location.state?.name) {
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  return (
    <div className={classes.wrapper}>
      <TitleAndDescription pageName="Disclaimer" />
      <button className={classes.back} onClick={goBack}>
        <PurpleArrowLeftIcon /> {RUSSIAN_LOCALE ? t('Back') : t('Back')}
        {!RUSSIAN_LOCALE &&
          location?.state?.name &&
          ` to ${location?.state?.name}`}
      </button>
      <div className={classes.container}>
        <h2 className={classes.title}>{t('Pricing Disclaimer_for page')}</h2>
        <p className={classes.text}>
          {t(
            'We\u00A0do\u00A0everything we\u00A0can to\u00A0ensure that the prices on\u00A0our website (-s) are correct, and we\u00A0try to\u00A0keep our prices constant. Sometimes we\u00A0need to\u00A0change the cost of\u00A0a\u00A0product (service, solution), either up\u00A0or\u00A0down'
          )}
          .
        </p>
        <ul className={classes.list}>
          <li className={classes['list-item']}>
            {t(
              "Prices may be\u00A0changed at\u00A0any time without further notice. We\u00A0reserve the right to\u00A0change our product's prices at\u00A0any time without further notice. However, if\u00A0you have ordered but not yet paid for a\u00A0product (service, solution), we\u00A0guarantee the price for one month from when the order was placed"
            )}
            .
          </li>
          <li className={classes['list-item']}>
            {t(
              "We\u00A0sell our product's through a\u00A0number of\u00A0different online channels and we\u00A0cannot always control when they are updated. We\u00A0reserve the right not to\u00A0accept a\u00A0price listed on\u00A0a\u00A0site other than our own"
            )}
            .
          </li>
        </ul>
      </div>
    </div>
  );
};
