import React, { FC } from 'react';
import { LoginContainer } from 'containers/Login';
import '../styles/main.scss';
import { RUSSIAN_LOCALE } from 'utils';
import { LoginContainerRu } from 'containers/LoginRu';

// todo add localization feature
export const Login: FC = () => {
  if (process.env.REACT_APP_ENABLE_KID === 'true') {
    if (RUSSIAN_LOCALE) {
      return <LoginContainerRu />;
    }
  }

  return <LoginContainer />;
};
