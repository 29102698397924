import React, { FC, useState, useEffect } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import { toNbsp } from 'tools/toNbsp';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';

import { EffectFade, Pagination, Autoplay } from 'swiper';

import slide1 from '../../../img/Registration/slide1.png';
import es_slide1 from '../../../img/Registration/es_slide1.png';
import slide2 from '../../../img/Registration/slide2.png';
import es_slide2 from '../../../img/Registration/es_slide2.png';
import slide3 from '../../../img/Registration/slide3.png';
import es_slide3 from '../../../img/Registration/es_slide3.png';

import slide1Retina from '../../../img/Registration/slide1@2x.png';
import es_slide1Retina from '../../../img/Registration/es_slide1@2x.png';
import slide2Retina from '../../../img/Registration/slide2@2x.png';
import es_slide2Retina from '../../../img/Registration/es_slide2@2x.png';
import slide3Retina from '../../../img/Registration/slide3@2x.png';
import es_slide3Retina from '../../../img/Registration/es_slide3@2x.png';

import slideMobile from '../../../img/Registration/mobile-solutions.png';
import es_slideMobile from '../../../img/Registration/mobile-solutions-es.png';
import slideMobile2 from '../../../img/Registration/mobile-cart.png';
import es_slideMobile2 from '../../../img/Registration/mobile-cart-es.png';
import slideMobile3 from '../../../img/Registration/mobile-projects.png';
import es_slideMobile3 from '../../../img/Registration/mobile-projects-es.png';

import i18n from '../../../i18n/i18n';

import classes from './SwiperBlock.module.scss';
import clsx from 'clsx';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { useTranslation } from 'react-i18next';

const imageByLng: any = {
  en: {
    image1: slide1,
    image2: slide2,
    image3: slide3,
    imageMobile1: slideMobile,
    imageMobile2: slideMobile2,
    imageMobile3: slideMobile3,
    retina1: slide1Retina,
    retina2: slide2Retina,
    retina3: slide3Retina,
  },
  es: {
    image1: es_slide1,
    image2: es_slide2,
    image3: es_slide3,
    imageMobile1: es_slideMobile,
    imageMobile2: es_slideMobile2,
    imageMobile3: es_slideMobile3,
    retina1: es_slide1Retina,
    retina2: es_slide2Retina,
    retina3: es_slide3Retina,
  },
  ru: {
    image1: slide1,
    image2: slide2,
    image3: slide3,
    imageMobile1: slideMobile,
    imageMobile2: slideMobile2,
    imageMobile3: slideMobile3,
    retina1: slide1Retina,
    retina2: slide2Retina,
    retina3: slide3Retina,
  },
};

const getMockData = (language: string) => [
  {
    id: 0,
    title: i18n.t('Start your journey with us and unlock the full potential!'),
    text: i18n.t('All digital marketing in one place'),
    image: imageByLng[language].image1,
    imageMobile: imageByLng[language].imageMobile1,
    retina: imageByLng[language].retina1,
  },
  {
    id: 1,
    title: i18n.t('It’s quick and easy to get started!'),
    text: i18n.t('Many services, unlimited sources, more result'),
    image: imageByLng[language].image2,
    imageMobile: imageByLng[language].imageMobile2,
    retina: imageByLng[language].retina2,
  },
  {
    id: 2,
    title: i18n.t('Get ready to explore and enjoy our solutions!'),
    text: i18n.t('Transparent process from order to production'),
    image: imageByLng[language].image3,
    imageMobile: imageByLng[language].imageMobile3,
    retina: imageByLng[language].retina3,
  },
];

export const SwiperBlock: FC = () => {
  const { isMediaTablet } = useWindowWidth();

  const { i18n } = useTranslation();
  const { t } = useTranslation();

  const [mockData, setMockData] = useState(getMockData(i18n.language));

  useEffect(() => {
    setMockData(getMockData(i18n.language));
  }, [i18n.language]);

  return (
    <div className={classes['swiper-wrapper']}>
      <Swiper
        pagination={true}
        effect={'fade'}
        noSwiping
        noSwipingClass="no-swiping"
        modules={[EffectFade, Pagination, Autoplay]}
        className={clsx(classes.swiper, 'no-swiping')}
        slidesPerView={1}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
      >
        {mockData.map((item: any, index: number) => (
          <SwiperSlide className={classes['swiper-slider']} key={index}>
            {({ isActive }) => (
              <div
                className={clsx(classes.content, isActive && classes.active)}
              >
                {!isMediaTablet && (
                  <img
                    src={item.image}
                    srcSet={item.retina}
                    alt=""
                    className={classes.image}
                  />
                )}
                {isMediaTablet && (
                  <img
                    src={item.imageMobile}
                    alt=""
                    className={classes.image}
                  />
                )}
                <div className={classes.body}>
                  <div className={classes.title}>{toNbsp(t(item.title))}</div>
                  {/* <div className={classes.text}>{toNbsp(t(item.text))}</div> */}
                </div>
              </div>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
