import React from 'react';
import { marketPlaceStore } from 'stores/marketPlaceStore';

const MarketPlaceContext = React.createContext({
  serviceTypes: [],
  businessTypes: [],
  services: [],
  activeHotDeals: [],
  dataTimeHotDeals: {
    dayDeals: 0,
    hourDeals: 0,
    minutesDeals: 0,
    featureDeals: false,
    activeDeals: false,
    startDate: new Date(),
    endDate: new Date(),
  },
  singleService: {},
  isLoadingServices: false,
  favoriteCount: 0,
  loadServiceTypes: () => {},
  loadBusinessTypes: () => {},
  loadServices: () => {},
  setServiceTypes: (serviceTypes: any) => {},
  setBusinessTypes: (businessTypes: any) => {},
  setServices: (services: any) => {},
  favoriteServices: [],
  setFavoriteCount: (count: number) => {},
  addServiceToFavorite: (serviceId: number) => {},
  removeServiceFromFavorite: (serviceId: number) => {},
  getServiceData: (slug: string): any => {},
  clearStore() {},
  loadHotDeals(params: any): any {},
  loadSingleService(serviceId: string): any {},
  loadActiveHotDeals(result: any) {},
  loadDataTimeHotDeals() {},
  isErrorServices: false,
});

export const MarketPlaceProvider = ({ children }: any) => (
  <MarketPlaceContext.Provider value={marketPlaceStore}>
    {children}
  </MarketPlaceContext.Provider>
);

export const useMarketPlaceStore = () => {
  const context = React.useContext(MarketPlaceContext);
  if (context === null) {
    throw new Error(
      'You have forgotten to wrap your root component with MarketPlaceProvider'
    );
  }
  return context;
};
