import * as React from 'react';
import { SVGProps } from 'react';
import { RUSSIAN_LOCALE } from 'utils';

export const LockGradientIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.25 9.0625H14.9219V4.6875C14.9219 3.30664 13.8027 2.1875 12.4219 2.1875H7.57812C6.19727 2.1875 5.07812 3.30664 5.07812 4.6875V9.0625H3.75C3.4043 9.0625 3.125 9.3418 3.125 9.6875V17.1875C3.125 17.5332 3.4043 17.8125 3.75 17.8125H16.25C16.5957 17.8125 16.875 17.5332 16.875 17.1875V9.6875C16.875 9.3418 16.5957 9.0625 16.25 9.0625ZM6.48438 4.6875C6.48438 4.08398 6.97461 3.59375 7.57812 3.59375H12.4219C13.0254 3.59375 13.5156 4.08398 13.5156 4.6875V9.0625H6.48438V4.6875ZM15.4688 16.4062H4.53125V10.4688H15.4688V16.4062ZM9.45312 13.6914V14.7266C9.45312 14.8125 9.52344 14.8828 9.60938 14.8828H10.3906C10.4766 14.8828 10.5469 14.8125 10.5469 14.7266V13.6914C10.7081 13.5757 10.8284 13.4117 10.8906 13.2232C10.9527 13.0347 10.9534 12.8314 10.8926 12.6425C10.8318 12.4535 10.7126 12.2888 10.5522 12.1719C10.3918 12.055 10.1985 11.992 10 11.992C9.80153 11.992 9.60817 12.055 9.44777 12.1719C9.28736 12.2888 9.16818 12.4535 9.10738 12.6425C9.04659 12.8314 9.0473 13.0347 9.10943 13.2232C9.17156 13.4117 9.2919 13.5757 9.45312 13.6914Z"
      fill="url(#paint0_linear_3263_27408)"
    />
    <defs>
      {RUSSIAN_LOCALE && (
        <linearGradient
          id="paint0_linear_3263_27408"
          x1="7.89744"
          y1="3"
          x2="18.8534"
          y2="19.8803"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E47267" />
          <stop offset="1" stopColor="#EB5945" />
        </linearGradient>
      )}
      {!RUSSIAN_LOCALE && (
        <linearGradient
          id="paint0_linear_3263_27408"
          x1="0.560002"
          y1="2.32"
          x2="18.88"
          y2="23.84"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6E3CFF" />
          <stop offset="1" stopColor="#4BA5FF" />
        </linearGradient>
      )}
    </defs>
  </svg>
);
