import React, { FC } from 'react';
import clsx from 'clsx';
import { CheckBoxIcon } from 'components/Icons/CheckBoxIcon';

interface Props {
  className?: string;
  onChange: (val: any) => void;
  disabled?: boolean;
  name?: string;
  checked?: boolean;
  label?: string;
}

export const Checkbox: FC<Props> = ({
  className,
  onChange,
  disabled,
  name,
  checked,
  label,
}) => (
  <label className={clsx('common__checkbox', className)}>
    <input
      onChange={() => onChange(!checked)}
      name={name}
      type="checkbox"
      className="common__checkbox--hidden"
      disabled={disabled}
      checked={checked || false}
    />
    <span className="common__checkbox-visible">
      {checked && <CheckBoxIcon width="11px" height="9px" />}
    </span>
    {label && <span className="common__checkbox-label">{label}</span>}
  </label>
);
