import { FilterAmountProps } from './FilterAmount.props';
import { useTranslation } from 'react-i18next';
import styles from './FilterAmount.module.scss';
import { ArrowUpIcon } from 'components/Icons/ArrowUpIcon';

const FilterAmount = ({ setOrdering }: FilterAmountProps) => {
  const { t } = useTranslation();

  return (
    <ul className={styles.list}>
      <li className={styles.item} onClick={() => setOrdering('amount')}>
        <ArrowUpIcon />
        {t('low to high')}
      </li>
      <li className={styles.item} onClick={() => setOrdering('-amount')}>
        <div className={styles.down}>
          <ArrowUpIcon />
        </div>
        {t('high to low')}
      </li>
    </ul>
  );
};

export default FilterAmount;
