import { Request } from 'tools/request';

interface Props {
  access: string;
  refresh: string;
}

export async function GetCurrencies({ access, refresh }: Props) {
  const result = await Request({
    type: 'GET',
    url: `${process.env.REACT_APP_API}/api/v1/currencies/?all=${true}`,
    isProtected: true,
    access: access,
  });

  return result;
}
