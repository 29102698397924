import { FC, Suspense } from 'react';
import { useLoaderData, Await } from 'react-router-dom';
import { PageLoader } from './PageLoader';

export interface Props {
  children?: any;
}

export const LoaderWrapper: FC<Props> = ({ children }) => {
  const loadedData: any = useLoaderData();

  return (
    <Suspense fallback={<PageLoader />}>
      <Await resolve={loadedData?.loader}>{children}</Await>
    </Suspense>
  );
};
