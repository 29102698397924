import Lottie from 'lottie-react';
import { FC } from 'react';
import classes from './PageLoader.module.scss';
import loader from './loader.json';
import loader_ru from './loading_ru.json';

import { RUSSIAN_LOCALE } from 'utils';

export interface PageLoaderProps {
  children?: any;
}

export const PageLoader: FC<PageLoaderProps> = ({ children }) => (
  <div className={classes.loaderWrapper}>
    <div className={classes['page-loader']}>
      <Lottie animationData={RUSSIAN_LOCALE ? loader_ru : loader} loop={true} />
    </div>
    {children}
  </div>
);
