import classes from './Account.module.scss';

export const accountMessages = (t: any) => [
  {
    title: t(
      'The Account page displays information that was entered during the registration process. The fields are grouped into three blocks'
    ),
    intro: '',
  },
  {
    element: `.${classes.inner}`,
    intro: t(
      'Personal information – email cannot be changed, as the account is linked to this identificator'
    ),
    position: 'right',
  },
  {
    element: `.${classes.inner}`,
    position: 'right',
    intro: t(
      'Business information - please note that these fields are only available for editing until the first project is created. After that, it will only be possible to change the information through your personal manager, as it affects the analysis and preparation of commercial offers'
    ),
  },
  {
    element: `.${classes.inner}`,
    intro: t('You can set a new password here'),
    position: 'right',
  },
  // {
  //     title: t('Congratulations! You are ready to work with KIT Global Platform, let’s grow your business together!'),
  //     intro: '',
  // }
];

export const accountMessagesRu = (t: any) => [
  {
    title: t(
      'The Account page displays information that was entered during the registration process. The fields are grouped into three blocks'
    ),
    intro: '',
  },
  {
    element: `.${classes.inner}`,
    intro: t(
      'Personal information – email cannot be changed, as the account is linked to this identificator'
    ),
    position: 'right',
  },
  {
    element: `.${classes.inner}`,
    position: 'right',
    intro: t(
      'Business information - please note that these fields are only available for editing until the first project is created. After that, it will only be possible to change the information through your personal manager, as it affects the analysis and preparation of commercial offers'
    ),
  }
];
