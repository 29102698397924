import { FC, useState, useEffect, useRef, useCallback } from 'react';

import { TitleAndDescription } from 'components/TitleAndDescription/TitleAndDescription';
import classes from './Projects.module.scss';

import { Filters } from './Filters';
import { Pagination } from 'components/Pagination';
import { ProjectCard } from './Card';
import { months } from 'constants/months';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useUserStore } from 'contexts/UserContext';
import { DEAL_STAGES, DealStageType } from '../../constants/dealStatuses';

import { Steps } from 'intro.js-react';
import { projectsHints } from './hints';
import '../CardsContainer/IntroTooltip.css';
import 'intro.js/introjs.css';
import {
  isOnboardingActive,
  setOnboardingOptions,
} from '../../tools/onboarding';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import { LABELS } from '../../constants/onboarding';
import { EmptyItems } from 'containers/EmptyItems';
import clsx from 'clsx';

interface Props {}

export const ProjectsContainer: FC<Props> = observer(({}) => {
  const { t } = useTranslation();
  const userStore = useUserStore();
  const userLocale = userStore.user?.language?.lang_code;
  let projects = userStore.projects;
  const isMobile = useWindowWidth().isSmallLaptop;
  const enableHints = isOnboardingActive(userStore?.pagesToOnboard?.projects);

  const { PAUSED, COMPLETED, CANCELLED, ACTIVE, ALL } = DEAL_STAGES;
  const { GOT_IT, NEXT, BACK } = LABELS(t);

  // filters
  const nonActiveStage = [PAUSED, COMPLETED, CANCELLED];
  const allCount = projects.length;
  const activeCount = projects.filter(
    (proj: any) => !nonActiveStage.includes(proj.deal_stage?.type.toLowerCase())
  ).length;
  const prodCount = projects.filter(
    (proj: any) => proj.deal_stage?.type.toLowerCase() === COMPLETED
  ).length;
  const pausedCount = projects.filter(
    (proj: any) => proj.deal_stage?.type.toLowerCase() === PAUSED
  ).length;
  const cancelledCount = projects.filter(
    (proj: any) => proj.deal_stage?.type.toLowerCase() === CANCELLED
  ).length;
  const [projectsToPaginate, setProjectsToPaginate] = useState<any>(projects);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState<DealStageType>(ALL as DealStageType);
  const chunkSize = 3;
  const [chunks, setChunks] = useState<any>([]);

  const filteredProjects = (projects: any) => {
    switch (filter) {
      case ALL:
        return projects;
      case COMPLETED:
        return projects.filter(
          (proj: any) => proj.deal_stage?.type.toLowerCase() === COMPLETED
        );
      case PAUSED:
        return projects.filter(
          (proj: any) => proj.deal_stage?.type.toLowerCase() === PAUSED
        );
      case CANCELLED:
        return projects.filter(
          (proj: any) => proj.deal_stage?.type.toLowerCase() === CANCELLED
        );
      case ACTIVE:
        return projects.filter(
          (proj: any) =>
            !nonActiveStage.includes(proj.deal_stage?.type.toLowerCase())
        );
      default:
        return projects;
    }
  };
  projects = filteredProjects(projects);

  const formatDate = (date: any) => {
    const newDate = new Date(date);
    return `${t(
      months[newDate.getMonth()]
    )} ${newDate.getDate()}, ${newDate.getFullYear()}`;
  };

  useEffect(() => {
    setProjectsToPaginate(filteredProjects(projects));
  }, [currentPage, filter, userLocale, userStore.projects]);

  useEffect(() => {
    if (JSON.stringify(projectsToPaginate) !== JSON.stringify(chunks.flat())) {
      const chunkedProjects = [];
      for (let i = 0; i < projectsToPaginate.length; i += chunkSize) {
        chunkedProjects.push(
          projectsToPaginate.length > 2
            ? projectsToPaginate.slice(i, i + chunkSize)
            : projectsToPaginate
        );
      }
      setChunks(chunkedProjects);
    }
  }, [projectsToPaginate, chunkSize, chunks]);

  const goToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleExit = useCallback(() => {
    setOnboardingOptions({ projects: true });
    userStore.enableOnboarding(true);
  }, [userStore]);

  return (
    <>
      <TitleAndDescription pageName={'Projects'} />
      <Steps
        enabled={isMobile && enableHints}
        steps={projectsHints(t)}
        options={{
          tooltipClass: 'customTooltip',
          showBullets: false,
          doneLabel: GOT_IT,
          nextLabel: NEXT,
          prevLabel: BACK,
        }}
        initialStep={0}
        onComplete={() => {
          handleExit();
        }}
        onExit={handleExit}
      />
      <div
        className={clsx(
          classes.wrapper,
          projects.length === 0 && 'empty-background'
        )}
      >
        <div style={{ position: 'relative', zIndex: 1 }}>
          <div className={classes.title}>
            {t('Projects')}
            <span>{allCount}</span>
          </div>

          <Filters
            all={allCount}
            active={activeCount}
            completed={prodCount}
            paused={pausedCount}
            cancelled={cancelledCount}
            filter={filter}
            setFilter={setFilter}
          />
        </div>

        <div className={classes.grid}>
          {(chunks.length > 1 ? chunks[currentPage - 1] : chunks[0])?.map(
            (project: any) => (
              <ProjectCard key={project.number} project={project} />
            )
          )}

          {projects.length === 0 && (
            <EmptyItems
              isBackground={false}
              title={'You have no projects'}
              description={
                'To initiate a project, select a Solution and add it to cart'
              }
            />
          )}
        </div>

        {projectsToPaginate.length > 3 && (
          <div className={classes.pagination}>
            <Pagination
              currentPage={currentPage}
              totalPages={chunks.length}
              setPageNumber={setCurrentPage}
              extraAction={() => goToTop()}
            />
          </div>
        )}
      </div>
    </>
  );
});
