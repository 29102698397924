import React from 'react';
import { Label } from './Label';
import { Icon } from '../Icon/Icon';
import style from './Input.module.scss';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { formatNumberWithSpace } from './formatNumberWithSpace';
import { CrossIcon } from 'components/Icons/CrossIcon';
import { TooltipContainer } from 'components/Tooltip/TooltipContainer';
import { QuestionIcon } from 'components/Icons/QuestionIcon';

export const Input = ({
  label,
  labelPosition,
  type,
  placeholder,
  disabled = false,
  readOnly = false,
  error,
  value = '',
  icon,
  iconJSX,
  iconFill,
  iconHeight,
  iconWidth,
  className,
  onChange,
  onBlur,
  onFocus,
  maxLength,
  onKeyDown,
  currency,
  inputForCurrency = false,
  inputLabel,
  clearIcon,
  helperText,
  onPaste,
}) => {
  const classNames = clsx(
    style.input,
    (icon || iconJSX) && style.inputicon,
    currency && style.inputCurrency,
    error && style.inputError,
    inputLabel && style.inputLabel,
    value && value.length === 0 && inputLabel && style.inputLabelEmptyInput,
    className
  );

  const formattedValue = inputForCurrency
    ? formatNumberWithSpace(value)
    : value;

  return (
    <div className={clsx(style.inputWrap, className)}>
      {label && <Label position={labelPosition}>{label}</Label>}

      <div className={style.inputInner}>
        {icon && !iconJSX && (
          <div className={style.iconWrap}>
            <Icon
              name={icon}
              width={iconWidth}
              height={iconHeight}
              iconFill={iconFill}
            />
          </div>
        )}

        {!icon && iconJSX && <div className={style.iconWrap}>{iconJSX}</div>}

        {currency && <div className={style.currencyWrap}>{currency}</div>}

        <input
          type={type}
          value={formattedValue || ''}
          className={classNames}
          placeholder={inputLabel ? '' : placeholder}
          disabled={disabled}
          readOnly={readOnly}
          onChange={(e) => onChange(e.target.value)}
          onFocus={onFocus}
          onBlur={onBlur}
          maxLength={maxLength || undefined}
          onKeyDown={onKeyDown}
          onPaste={onPaste || undefined}
        />

        {inputLabel && (
          <div
            className={clsx(
              style.inputLabelPlaceholder,
              (icon || iconJSX) && style.inputIconPlaceholder,
              value.length > 0 && style.inputLabelPlaceholderActive,
              style.inputLabelPlaceholderDefault
            )}
          >
            {placeholder}
          </div>
        )}

        {clearIcon && value.length > 0 && (
          <CrossIcon
            className={style.clearIcon}
            onClick={() => onChange('')}
            width={'15px'}
            height={'15px'}
          />
        )}

        {helperText && (
          <TooltipContainer
            text={helperText}
            customClasses={'kit-ui-block'}
            position={'top'}
            className={style.error}
          >
            <QuestionIcon fill="#F5222D" width="18px" height="18px" />
          </TooltipContainer>
        )}
      </div>
    </div>
  );
};

Input.defaultProps = {
  label: '',
  labelPosition: '',
  type: '',
  placeholder: '',
  disabled: false,
  readOnly: false,
  error: null,
  value: null,
  icon: null,
  iconWidth: '18px',
  iconHeight: '18px',
  iconFill: '#A09CAC',
  className: '',
  maxLength: undefined,
  currency: undefined,
  iconJSX: undefined,
};

Input.propTypes = {
  label: PropTypes.string,
  labelPosition: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  error: PropTypes.bool,
  value: PropTypes.string,
  icon: PropTypes.string,
  iconWidth: PropTypes.string,
  iconHeight: PropTypes.string,
  iconFill: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  maxLength: PropTypes.number | undefined,
  onKeyDown: PropTypes.func,
  onPaste: PropTypes.func | undefined,
  currency: PropTypes.string | undefined,
  inputForCurrency: PropTypes.bool,
  iconJSX: PropTypes.any | undefined,
  inputLabel: PropTypes.bool,
  clearIcon: PropTypes.bool,
  helperText: PropTypes.string | undefined,
};
