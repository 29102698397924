import React, { FC, useEffect, useRef, useState } from 'react';
import classes from './index.module.scss';
import clsx from 'classnames';
import { Input } from '../Input';
import { ArrowDownIcon } from '../Icons/ArrowDownIcon';
import { Button } from '../Button';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import UiIcon from '../Icon';
import { cartStore } from '../../stores/cartStore';
import { useDisableScrolling } from '../../hooks/useDisableScrolling';

export interface SelectData {
  id: number | string;
  name: string;
  description?: string;
}

interface SelectProps {
  options?: SelectData[];
  placeholder?: string;
  setSelect?: (val: any) => void;
  select: any;
  title?: string;
  open?: boolean;
  setOpen?: (val: boolean) => void;
  defaultValue?: string;
  onClick?: () => void;
  onSelect?: () => void;
  boxClassName?: string;
  wrapperClassName?: string;
  dropdownClassName?: string;
}

const Select: FC<SelectProps> = ({
  options,
  placeholder,
  setSelect,
  select,
  title,
  open,
  setOpen,
  defaultValue,
  onClick,
  onSelect,
  boxClassName,
  wrapperClassName,
  dropdownClassName,
}) => {
  const isMobile = useWindowWidth().isMediaTablet;
  useDisableScrolling(isMobile && open);

  const [isClosing, setIsClosing] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    cartStore.setFixedButtonSize(56, open ? 9999 : 2);
    if (!open && isClosing) {
      const timer = setTimeout(() => {
        setIsClosing(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [open, isClosing]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectRef?.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setOpen && setOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setOpen]);

  const handleClose = () => {
    if (setOpen) {
      setIsClosing(true);
      setOpen(false);
    }
  };

  // useEffect(() => {
  //   onSelect && onSelect();
  // }, [onSelect, select]);

  const selectComponent = (
    <div
      ref={selectRef}
      className={clsx(classes.selectBox, boxClassName)}
      onClick={() => onClick && onClick()}
    >
      <div className={classes.title}>{title}</div>
      <div className={clsx(classes.wrapper, wrapperClassName)}>
        <div
          onClick={() => {
            if (setOpen) setOpen(!open);
          }}
          className={clsx(classes['main-input'], open && classes.dropActive)}
        >
          <Input
            className={classes.drop}
            readOnly={true}
            placeholder={placeholder}
            value={select?.name || defaultValue}
          />
          <div className={clsx(classes.arrow, open && classes.arrowActive)}>
            <ArrowDownIcon />
          </div>
        </div>
        {open && (
          <div className={clsx(classes.dropdown, dropdownClassName)}>
            <div className={classes.list}>
              {options &&
                options?.map((item: SelectData, index: number) => (
                  <div
                    key={`${item.id}${index}`}
                    onClick={() => {
                      if (setOpen) setOpen(false);
                      if (item?.id !== select?.id) {
                        if (setSelect) setSelect(item);
                        if (onSelect) onSelect();
                      }
                    }}
                    className={clsx(
                      classes.item,
                      (item.id === select?.id ?? item?.name === select?.name) &&
                        classes.active
                    )}
                  >
                    <span>{item?.name}</span>
                    {item?.id === select?.id && (
                      <UiIcon
                        name="CheckIcon"
                        additionalClassName={classes.checkIcon}
                      />
                    )}
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );

  const mobileSelect =
    open || isClosing ? (
      <>
        <div
          className={clsx(classes.overlay, { [classes.close]: isClosing })}
          onClick={() => {
            setOpen && setOpen(!open);
            handleClose();
          }}
        />
        <div
          className={clsx(classes.fixedButton, { [classes.close]: isClosing })}
        >
          <div className={classes.title}>
            <div>{title}</div>
            <UiIcon
              name="CrossIcon"
              onClick={() => {
                setOpen && setOpen(!open);
                handleClose();
              }}
            />
          </div>
          <div className={classes.items}>
            {options?.map((item: SelectData, index: number) => (
              <Button
                key={`${item.id}${index}`}
                className={clsx(
                  classes.button,
                  item?.id === select?.id && classes.active
                )}
                onClick={() => {
                  onClick && onClick();
                  setOpen && setOpen(!open);
                  if (item?.id !== select?.id) {
                    if (onSelect) onSelect();
                    if (setSelect) setSelect(item);
                  }
                  handleClose();
                }}
              >
                {item?.name}
                {item?.id === select?.id && (
                  <UiIcon
                    name="CheckIcon"
                    additionalClassName={classes.checkIcon}
                  />
                )}
              </Button>
            ))}
          </div>
        </div>
      </>
    ) : null;

  return isMobile ? mobileSelect : selectComponent;
};

export default Select;
