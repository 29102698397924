import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import classes from './Certificates.module.scss';

interface Props {
  data: {
    id: number;
    image: string;
    is_active: boolean;
    name: string;
  }[];
}

export const Certificates: FC<Props> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>{t('Certificates')}</div>
      <div className={classes.images}>
        {data.map((item) => (
          <div key={item.id} className={classes.item}>
            <img className={classes.image} src={item.image} alt="" />
          </div>
        ))}
      </div>
    </div>
  );
};
