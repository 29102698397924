import React, { FC } from 'react';
import classes from './Toast.module.scss';
import { ToastContainer } from 'react-toastify';
import clsx from 'clsx';
import { CheckCircleIcon } from 'components/Icons/CheckCirleIcon';
import { UnionIcon } from 'components/Icons/UnionIcon';
import { InfoIcon } from 'components/Icons/InfoIcon';

interface Props {
  type: string;
  children: string;
  toastClass?: any;
  toastIconClass?: any;
  childrenClass?: any;
}

export const Toast: FC<Props> = ({
  type,
  children,
  toastClass,
  childrenClass,
  toastIconClass,
}) => (
  <div className={clsx(classes['toast-container'], classes[type], toastClass)}>
    <div className={clsx(classes.toast, classes[type], toastClass)}>
      <div className={clsx(classes.icon, toastIconClass)}>
        {type === 'success' && (
          <CheckCircleIcon fill={'#00C853'} width={'100%'} height={'100%'} />
        )}
        {type === 'warning' && (
          <InfoIcon fill={'#F3CA37'} width={'100%'} height={'100%'} />
        )}
        {type === 'error' && (
          <UnionIcon fill={'#F5222D'} width={'100%'} height={'100%'} />
        )}
      </div>
      <span className={clsx(classes.text, childrenClass)}>{children}</span>
    </div>
  </div>
);
