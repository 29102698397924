import * as React from 'react';
import { SVGProps } from 'react';

export const ArrowUpIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="10"
    height="13"
    viewBox="0 0 10 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 11.8332V1.1665M5 1.1665L1 5.1665M5 1.1665L9 5.1665"
      stroke="#F14B4B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
