import { Request } from 'tools/request';

interface Props {
  cookie_groups_varnames: string;
  access: string;
  refresh: string;
}

export async function DeclineCookies({
  cookie_groups_varnames,
  access,
  refresh,
}: Props) {
  const result = await Request({
    type: 'POST',
    url: `${process.env.REACT_APP_API}/api/v1/users/decline_cookies/`,
    isProtected: true,
    data: { cookie_groups_varnames: cookie_groups_varnames },
    access: access,
  });

  return result;
}
