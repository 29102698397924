import React, { useEffect, useState } from 'react';
import { Label } from './Label';
import { Icon } from '../Icon/Icon';
import style from './Input.module.scss';
import clsx from 'clsx';
import PhoneInput from 'react-phone-input-2';
import PropTypes from 'prop-types';

export const PhoneInputMask = ({
  label,
  labelPosition,
  placeholder,
  disabled,
  error,
  value,
  icon,
  iconFill,
  iconHeight,
  iconWidth,
  className,
  onChange,
  onBlur,
}) => {
  const [activePlaceholder, setActivePlaceHolder] = useState(placeholder);
  const classNames = clsx(style.input, icon && style.inputicon);

  useEffect(() => {
    setActivePlaceHolder(placeholder);
  }, [placeholder]);

  return (
    <div className={clsx(style.inputWrap, className)}>
      {label && <Label position={labelPosition}>{label}</Label>}

      <div className={style.inputInner}>
        {icon && (
          <div className={style.iconWrap}>
            <Icon
              name={icon}
              width={iconWidth}
              height={iconHeight}
              iconFill={iconFill}
            />
          </div>
        )}
        <PhoneInput
          specialLabel={''}
          country={''}
          inputProps={{
            autoComplete: 'false',
          }}
          value={value}
          containerClass={classNames}
          inputClass={error && style.inputError}
          placeholder={activePlaceholder}
          disabled={disabled}
          onChange={(e) => onChange(e)}
          onFocus={() => setActivePlaceHolder('+')}
          onBlur={() => {
            setActivePlaceHolder(placeholder);
            onBlur && onBlur();
          }}
        />
      </div>
    </div>
  );
};

PhoneInputMask.defaultProps = {
  label: '',
  labelPosition: '',
  type: '',
  placeholder: '',
  disabled: false,
  error: null,
  value: null,
  icon: null,
  iconWidth: '18px',
  iconHeight: '18px',
  iconFill: '#A09CAC',
  className: '',
};

PhoneInputMask.propTypes = {
  label: PropTypes.string,
  labelPosition: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  value: PropTypes.string,
  icon: PropTypes.string,
  iconWidth: PropTypes.string,
  iconHeight: PropTypes.string,
  iconFill: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  setShowCheks: PropTypes.func,
  onBlur: PropTypes.func,
};
