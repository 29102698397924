import React, { useState } from 'react';
import { Label } from './Label';
import classes from './Input.module.scss';
import TextareaAutosize from 'react-textarea-autosize';
import clsx from 'clsx';
import PropTypes from 'prop-types';

export const Textarea = ({
  label,
  labelPosition,
  placeholder,
  disabled,
  error,
  value,
  onChange,
  onBlur,
  onFocus,
  maxLength,
}) => {
  const className = clsx(classes.textarea, error && classes.textareaError);
  // const [value, setValue] = useState(value)

  return (
    <div className={classes.inputWrap}>
      {label && <Label position={labelPosition}>{label}</Label>}
      <TextareaAutosize
        className={className}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        onChange={(e) => onChange(e.target.value)}
        // onFocus={onFocus}
        // onBlur={onBlur}
        maxLength={maxLength}
      />
    </div>
  );
};

Textarea.defaultProps = {
  label: '',
  labelPosition: 'left',
  placeholder: '',
  disabled: false,
  error: '',
  val: '',
  height: '',
  maxLength: undefined,
};

Textarea.propTypes = {
  label: PropTypes.string,
  labelPosition: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  val: PropTypes.string,
  height: PropTypes.string,
  maxLength: PropTypes.number | PropTypes.undefined,
  onChange: PropTypes.any,
  onFocus: PropTypes.any,
  onBlur: PropTypes.any,
};
