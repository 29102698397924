import localeClasses from './LocalePageContainer.module.scss';

export const localeMessages = (userName: string, t: any) => [
  {
    element: `.${localeClasses.preference}`,
    title: `${userName}, ${t('welcome to KIT Global Platform! ')}`,
    intro: t(
      "Let's start with defining your local preferences to show more relevant content. Please set appropriate currency, country and language that you want to see in the interface"
    ),
  },
];
