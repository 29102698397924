import { useMarketPlaceStore } from 'contexts/marketPlaceContext';
import { useCallback, Dispatch, SetStateAction } from 'react';

import { NavigateFunction } from 'react-router-dom';
import { pushToDataLayer } from '../tools/analytics';

export const useFavorites = (
  data: any,
  isFavorite: boolean,
  setIsFavorite: Dispatch<SetStateAction<boolean>>,
  navigate: NavigateFunction
) => {
  const marketPlaceStore = useMarketPlaceStore();

  const changeFavorites = useCallback(async () => {
    if (!isFavorite) {
      marketPlaceStore.addServiceToFavorite(data.id);
      pushToDataLayer('addToFavorite', 'buttonClick', 'GAForms');
      setIsFavorite(true);
    } else {
      marketPlaceStore.removeServiceFromFavorite(data.id);
      setIsFavorite(false);
    }
  }, [isFavorite]);

  return changeFavorites;
};
