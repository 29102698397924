import classes from './CardsContainer.module.scss';
import productListClasses from './ProductList/ProductList.module.scss';
import searchClasses from '../../components/SearchComponent/SearchComponent.module.scss';
import productCardClasses from './ProductList/ProductCard/ProductCard.module.scss';

export const solutionsMessages = (t: any) => [
  {
    element: `.${productListClasses.list}`,
    title: t(
      'Solutions is where you can find various services to meet your needs. Each card displays the type of product, the name of the service, a brief description, the price and the type of pricing'
    ),
    intro: '',
    scrollToElement: false,
    position: 'left',
    scrollTo: 'tooltip',
  },
  {
    element: `.${productListClasses.left}`,
    scrollTo: 'tooltip',
    position: 'left',
    intro: t(
      'The "Free" label will additionally indicate that the service is free of charge, and the "New" label is used to mark catalog novelties. You could also see solutions with active discounts'
    ),
  },
  {
    element: `.${classes.left} div`,
    scrollTo: 'tooltip',
    intro: t(
      'To narrow down your search, you can use the filters to match your requirements by type of service, or business, or price range...'
    ),
  },
  {
    element: `.${searchClasses.search__container}`,
    scrollTo: 'tooltip',
    intro: t(
      'Use the search to quickly check if we have a solution you are interested in'
    ),
  },
  {
    element: `.${productListClasses.pagination}`,
    scrollTo: 'tooltip',
    intro: t(
      'Use pagination or "See more" button to navigate through the list'
    ),
  },
  {
    element: `.${productListClasses['request-card']}`,
    scrollTo: 'tooltip',
    position: 'left',
    intro: t(
      'If you have not found a suitable solution in the catalog, you have the opportunity to send an application for us to offer something else'
    ),
  },
  {
    element: `.${productCardClasses['mobile-buttons']} .${productCardClasses['not-in-cart']}`,
    scrollTo: 'tooltip',
    intro: t(
      'By clicking the "Add to Cart" button on any solution from the list or from detailed page you can save it in your Cart for further order'
    ),
    disableInteraction: false,
  },
  {
    element: `.${productCardClasses['mobile-button']}`,
    scrollTo: 'tooltip',
    intro: t(
      'If you are interested in the solution but are not yet ready to make a decision about ordering it, you can add it to your favorites'
    ),
  },
];

export const solutionsMessagesWithoutPaginationWithServices = (t: any) => [
  {
    element: `.${productListClasses.list}`,
    title: t(
      'Solutions is where you can find various services to meet your needs. Each card displays the type of product, the name of the service, a brief description, the price and the type of pricing'
    ),
    intro: '',
    scrollToElement: false,
    position: 'left',
    scrollTo: 'tooltip',
  },
  {
    element: `.${productListClasses.left}`,
    scrollTo: 'tooltip',
    position: 'left',
    intro: t(
      'The "Free" label will additionally indicate that the service is free of charge, and the "New" label is used to mark catalog novelties. You could also see solutions with active discounts'
    ),
  },
  {
    element: `.${classes.left} div`,
    scrollTo: 'tooltip',
    intro: t(
      'To narrow down your search, you can use the filters to match your requirements by type of service, or business, or price range...'
    ),
  },
  {
    element: `.${searchClasses.search__container}`,
    scrollTo: 'tooltip',
    intro: t(
      'Use the search to quickly check if we have a solution you are interested in'
    ),
  },
  {
    element: `.${productListClasses['request-card']}`,
    scrollTo: 'tooltip',
    position: 'left',
    intro: t(
      'If you have not found a suitable solution in the catalog, you have the opportunity to send an application for us to offer something else'
    ),
  },
  {
    element: `.${productCardClasses['mobile-buttons']} .${productCardClasses['not-in-cart']}`,
    scrollTo: 'tooltip',
    intro: t(
      'By clicking the "Add to Cart" button on any solution from the list or from detailed page you can save it in your Cart for further order'
    ),
  },
  {
    element: `.${productCardClasses['mobile-button']}`,
    scrollTo: 'tooltip',
    intro: t(
      'If you are interested in the solution but are not yet ready to make a decision about ordering it, you can add it to your favorites'
    ),
  },
];

export const solutionsMessagesWithPaginationWithServices = (t: any) => [
  {
    element: `.${productListClasses.list}`,
    title: t(
      'Solutions is where you can find various services to meet your needs. Each card displays the type of product, the name of the service, a brief description, the price and the type of pricing'
    ),
    intro: '',
    scrollToElement: false,
    position: 'left',
    scrollTo: 'tooltip',
  },
  {
    element: `.${productListClasses.left}`,
    scrollTo: 'tooltip',
    position: 'left',
    intro: t(
      'The "Free" label will additionally indicate that the service is free of charge, and the "New" label is used to mark catalog novelties. You could also see solutions with active discounts'
    ),
  },
  {
    element: `.${classes.left} div`,
    scrollTo: 'tooltip',
    intro: t(
      'To narrow down your search, you can use the filters to match your requirements by type of service, or business, or price range...'
    ),
  },
  {
    element: `.${searchClasses.search__container}`,
    scrollTo: 'tooltip',
    intro: t(
      'Use the search to quickly check if we have a solution you are interested in'
    ),
  },
  {
    element: `.${productListClasses.pagination}`,
    scrollTo: 'tooltip',
    intro: t(
      'Use pagination or "See more" button to navigate through the list'
    ),
  },
  {
    element: `.${productListClasses['request-card']}`,
    scrollTo: 'tooltip',
    position: 'left',
    intro: t(
      'If you have not found a suitable solution in the catalog, you have the opportunity to send an application for us to offer something else'
    ),
  },
  {
    element: `.${productCardClasses['mobile-buttons']} .${productCardClasses['not-in-cart']}`,
    scrollTo: 'tooltip',
    intro: t(
      'By clicking the "Add to Cart" button on any solution from the list or from detailed page you can save it in your Cart for further order'
    ),
  },
  {
    element: `.${productCardClasses['mobile-button']}`,
    scrollTo: 'tooltip',
    intro: t(
      'If you are interested in the solution but are not yet ready to make a decision about ordering it, you can add it to your favorites'
    ),
  },
];

export const solutionsMessagesWithPaginationWithoutServices = (t: any) => [
  {
    element: `.${productListClasses.list}`,
    title: t(
      'Solutions is where you can find various services to meet your needs. Each card displays the type of product, the name of the service, a brief description, the price and the type of pricing'
    ),
    intro: '',
    scrollToElement: false,
    position: 'left',
    scrollTo: 'tooltip',
  },
  {
    element: `.${classes.left} div`,
    scrollTo: 'tooltip',
    intro: t(
      'To narrow down your search, you can use the filters to match your requirements by type of service, or business, or price range...'
    ),
  },
  {
    element: `.${searchClasses.search__container}`,
    scrollTo: 'tooltip',
    intro: t(
      'Use the search to quickly check if we have a solution you are interested in'
    ),
  },
  {
    element: `.${productListClasses.pagination}`,
    scrollTo: 'tooltip',
    intro: t(
      'Use pagination or "See more" button to navigate through the list'
    ),
  },
  {
    element: `.${productListClasses['request-card']}`,
    scrollTo: 'tooltip',
    position: 'left',
    intro: t(
      'If you have not found a suitable solution in the catalog, you have the opportunity to send an application for us to offer something else'
    ),
  },
  {
    element: `.${productCardClasses['mobile-buttons']} .${productCardClasses['not-in-cart']}`,
    scrollTo: 'tooltip',
    intro: t(
      'By clicking the "Add to Cart" button on any solution from the list or from detailed page you can save it in your Cart for further order'
    ),
  },
  {
    element: `.${productCardClasses['mobile-button']}`,
    scrollTo: 'tooltip',
    intro: t(
      'If you are interested in the solution but are not yet ready to make a decision about ordering it, you can add it to your favorites'
    ),
  },
];

export const solutionsMessagesWithoutPaginationAndWithoutServices = (
  t: any
) => [
  {
    element: `.${productListClasses.list}`,
    title: t(
      'Solutions is where you can find various services to meet your needs. Each card displays the type of product, the name of the service, a brief description, the price and the type of pricing'
    ),
    intro: '',
    scrollToElement: false,
    position: 'left',
    scrollTo: 'tooltip',
  },
  {
    element: `.${classes.left} div`,
    scrollTo: 'tooltip',
    intro: t(
      'To narrow down your search, you can use the filters to match your requirements by type of service, or business, or price range...'
    ),
  },
  {
    element: `.${searchClasses.search__container}`,
    scrollTo: 'tooltip',
    intro: t(
      'Use the search to quickly check if we have a solution you are interested in'
    ),
  },
  {
    element: `.${productListClasses['request-card']}`,
    scrollTo: 'tooltip',
    position: 'left',
    intro: t(
      'If you have not found a suitable solution in the catalog, you have the opportunity to send an application for us to offer something else'
    ),
  },
  {
    element: `.${productCardClasses['mobile-buttons']} .${productCardClasses['not-in-cart']}`,
    scrollTo: 'tooltip',
    intro: t(
      'By clicking the "Add to Cart" button on any solution from the list or from detailed page you can save it in your Cart for further order'
    ),
  },
  {
    element: `.${productCardClasses['mobile-button']}`,
    scrollTo: 'tooltip',
    intro: t(
      'If you are interested in the solution but are not yet ready to make a decision about ordering it, you can add it to your favorites'
    ),
  },
];
