import { FC } from 'react';
import clsx from 'classnames';
import classes from './TabFilter.module.scss';
import { TTabFilterProps, TTab } from './types';

export const TabFilter: FC<TTabFilterProps> = ({
  tabArray,
  activeTab,
  setActiveTab,
}) => (
  <div className={classes.wrapper}>
    {tabArray.length > 0 &&
      tabArray.map((tab: TTab) => (
        <div
          role="button"
          tabIndex={-1}
          onClick={() => setActiveTab(tab)}
          className={clsx(
            classes.filter,
            activeTab.id === tab.id && classes.active,
            tab.count === 0 && classes.disabled
          )}
          key={tab.name}
        >
          {tab.name}
          <div className={classes.count}>{tab.count}</div>
        </div>
      ))}
  </div>
);
