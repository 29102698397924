import _ from 'lodash';
import { FC } from 'react';
import { HotDealsItem } from '../HotDealsItem';

interface Props {
  activeDiscount: {
    name?: string;
    discounts: {
      type: string;
      is_active: boolean;
    }[];
    has_active_discounts: boolean;
    cost: string;
    cost_with_discounts: string;
  };
  isFeature: boolean;
}

export const HotDealsContainer: FC<Props> = ({ activeDiscount, isFeature }) => {
  const dealsArray = activeDiscount.discounts.filter(
    (item) => item.type === 'HOT_DEAL' && item.is_active
  );

  return (
    <>
      {dealsArray.map((item: any) => (
        <HotDealsItem
          key={item.id}
          deals={item}
          infoDiscount={activeDiscount}
          isFeature={isFeature}
        />
      ))}
    </>
  );
};
