import classes from './ProjectDealStages.module.scss';
import { CheckCirleIconPurple } from 'components/Icons/CheckCirleIconPurple';
import { PurpleTimeIcon } from 'components/Icons/PurpleTimeIcon';
import { PurpleEmptyIcon } from 'components/Icons/PurpleEmptyIcon';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { CancelledIcon } from 'components/Icons/CancelledIcon';
import { PausedIcon } from 'components/Icons/PausedIcon';
import { DEAL_STAGES } from 'constants/dealStatuses';
import { DownloadIcon } from 'components/Icons/DownloadIcon';
import { RUSSIAN_LOCALE } from 'utils';
import { formatRuShortDate } from 'tools/formattedDate';

const concatAndRemoveDuplicates = (
  allStages: { name: string; type?: string }[],
  currentStages: { id: string; name: string; type?: string }[]
) => {
  // Check for the presence of 'PAUSED' and 'CANCELLED' stages in the current stages
  const hasPausedStage = currentStages.some((stage) => stage.type === 'PAUSED');
  const hasCancelledStage = currentStages.some(
    (stage) => stage.type === 'CANCELLED'
  );
  const hasCompletedStage = currentStages.some(
    (stage) => stage.type === 'COMPLETED'
  );
  // Add stages from 'allStages' only if they are not 'PAUSED' or 'CANCELLED'
  // or such stages exist in 'currentStages'
  const result = allStages
    .filter((stage) => {
      if (stage.type === 'PAUSED' && !hasPausedStage) return false;
      if (stage.type === 'CANCELLED' && !hasCancelledStage) return false;
      if (stage.type === 'COMPLETED' && !hasCompletedStage) return false;
      return true;
    })
    .map((stage) => {
      const passedStage = currentStages.find((ps) => ps.name === stage.name);
      return passedStage ? { ...passedStage } : stage;
    });

  return result;
};

export const ProjectsDealStagesContainer = observer(
  ({ project }: { project: any }) => {
    const projectAllStage = project?.deal_stages;
    const stageArray = [...project.deal_stages, ...project.all_stages];
    const { PAUSED, CANCELLED, COMPLETED } = DEAL_STAGES;

    // Add pause or cancel stages to the place where the last active stage was
    const pausedStage = projectAllStage.find(
      (stage: any) => stage.type.toLowerCase() === PAUSED.toLowerCase()
    );
    const cancelledStage = projectAllStage.find(
      (stage: any) => stage.type.toLowerCase() === CANCELLED.toLowerCase()
    );
    const completedStage = projectAllStage.find(
      (stage: any) => stage.type.toLowerCase() === COMPLETED.toLowerCase()
    );
    let lastIndex = -1;
    for (let i = 0; i < projectAllStage.length; i++) {
      if (projectAllStage[i].id !== undefined) {
        lastIndex = i;
      }
    }
    if (pausedStage) {
      projectAllStage.splice(lastIndex + 1, 0, pausedStage);
    }
    if (cancelledStage) {
      const index = pausedStage ? 2 : 1;
      projectAllStage.splice(lastIndex + index, 0, cancelledStage);
    }
    if (completedStage) {
      projectAllStage.splice(lastIndex + 1, 0, completedStage);
    }

    const formatDate = (dateString: string) => {
      const options: any = { year: 'numeric', month: 'short', day: '2-digit' };
      const date: Date = new Date(dateString);
      return date.toLocaleDateString('en-US', options);
    };

    const getStepIcon = (
      stageName: string,
      stageType: string = '',
      id: number = 0,
      planEndDate: string = '',
      factEndDate: string = ''
    ) => {
      const projectFilePath = project.files.find(
        (el: any) => el.deal_stage === id
      )?.file;
      const helpText = stageArray.find(
        (obj: any) => obj.name === stageName
      )?.description;
      const fileName = projectFilePath?.match(/\/files\/(.+)/)[1];
      const shortFileName =
        fileName?.length > 20 ? `${fileName?.slice(0, 20)}...` : fileName;

      const additionalStageIcon = (stageType: string, isTimeIcon?: boolean) => {
        const type = stageType.toLowerCase();
        if (type === PAUSED) {
          return <PausedIcon />;
        } else if (type === CANCELLED) {
          return <CancelledIcon />;
        } else if (isTimeIcon) {
          return <PurpleTimeIcon />;
        } else {
          return <CheckCirleIconPurple />;
        }
      };

      if (planEndDate && factEndDate) {
        let factRuDate = null;

        if (RUSSIAN_LOCALE) {
          factRuDate = formatRuShortDate(factEndDate);
        }

        return (
          <div className={classes.step}>
            <div className={clsx(classes.name, classes.past)}>
              <span className={classes.step__date}>
                {RUSSIAN_LOCALE ? factRuDate : formatDate(factEndDate)}
              </span>
              {additionalStageIcon(stageType)}
              <div className={classes.step__block}>
                <span>{stageName}</span>
                <p>{helpText}</p>
                {projectFilePath && (
                  <a
                    href={process.env.REACT_APP_API + projectFilePath}
                    target="_blank"
                    className={classes.map__link}
                    title={fileName}
                    rel="noreferrer"
                  >
                    {shortFileName}
                    <DownloadIcon />
                  </a>
                )}
              </div>
            </div>
          </div>
        );
      } else if (planEndDate && !factEndDate) {
        let planRuDate = null;

        if (RUSSIAN_LOCALE) {
          planRuDate = formatRuShortDate(planEndDate);
        }

        return (
          <div className={clsx(classes.step, classes.step_empty)}>
            <div className={clsx(classes.name, classes.past)}>
              <span className={classes.step__date}>
                {RUSSIAN_LOCALE ? planRuDate : formatDate(planEndDate)}
              </span>
              {additionalStageIcon(stageType, true)}
              <div className={classes.step__block}>
                <span>{stageName}</span>
                <p>{helpText}</p>
                {projectFilePath && (
                  <a
                    href={process.env.REACT_APP_API + projectFilePath}
                    target="_blank"
                    className={classes.map__link}
                    title={fileName}
                    rel="noreferrer"
                  >
                    {shortFileName}
                  </a>
                )}
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className={clsx(classes.step, classes.step_empty)}>
            <div className={clsx(classes.name, classes.past)}>
              <span className={classes.step__date}></span>
              <PurpleEmptyIcon />
              <div className={classes.step__block}>
                <span>{stageName}</span>
                <p>{helpText}</p>
              </div>
            </div>
          </div>
        );
      }
    };

    return (
      <div className={classes.map}>
        {concatAndRemoveDuplicates(project.all_stages, project.deal_stages).map(
          (el: any) => {
            if (el.id) {
              return getStepIcon(
                el.name,
                el.type,
                el.id,
                el.plan_end_date,
                el.fact_end_date
              );
            } else {
              return getStepIcon(el.name);
            }
          }
        )}
      </div>
    );
  }
);
