import React, { FC } from 'react';
import { RegistrationContainer } from '../containers/Registration';
import { RegistrationRUContainer } from '../containers/RegistrationRU';
import '../styles/main.scss';
import { RUSSIAN_LOCALE } from 'utils';

// todo add localization feature
export const Registration: FC = () => {
  if (RUSSIAN_LOCALE) {
    return <RegistrationRUContainer />;
  }

  return <RegistrationContainer />;
};
