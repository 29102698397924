import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import clsx from 'classnames';

import classes from './Filters.module.scss';

interface Props {
  all: number;
  paid: number;
  topay: number;
  cancelled: number;
  filter: 'all' | 'paid' | 'topay' | 'cancelled';
  setFilter: (val: 'all' | 'paid' | 'topay' | 'cancelled') => void;
}

export const Filters: FC<Props> = ({
  all,
  paid,
  topay,
  cancelled,
  filter,
  setFilter,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <div
        role="button"
        tabIndex={-1}
        onClick={() => {
          if (all !== 0) {
            setFilter('all');
          }
        }}
        className={clsx(classes.filter, filter === 'all' && classes.active)}
      >
        {t('All')}
        <div className={clsx(classes.count, all > 9 && classes.countMedium)}>
          {all}
        </div>
      </div>
      <div
        role="button"
        tabIndex={-1}
        onClick={() => {
          if (paid !== 0) {
            setFilter('paid');
          }
        }}
        className={clsx(
          classes.filter,
          filter === 'paid' && classes.active,
          paid === 0 && classes.disabled
        )}
      >
        {t('Paid')}
        <div className={clsx(classes.count, paid > 9 && classes.countMedium)}>
          {paid}
        </div>
      </div>
      <div
        role="button"
        tabIndex={-1}
        onClick={() => {
          if (topay !== 0) {
            setFilter('topay');
          } else {
          }
        }}
        className={clsx(
          classes.filter,
          filter === 'topay' && classes.active,
          topay === 0 && classes.disabled
        )}
      >
        {t('To pay')}
        <div className={clsx(classes.count, topay > 9 && classes.countMedium)}>
          {topay}
        </div>
      </div>
      <div
        role="button"
        tabIndex={-1}
        onClick={() => {
          if (cancelled !== 0) {
            setFilter('cancelled');
          } else {
          }
        }}
        className={clsx(
          classes.filter,
          filter === 'cancelled' && classes.active,
          cancelled === 0 && classes.disabled
        )}
      >
        {t('Cancelled')}
        <div
          className={clsx(classes.count, cancelled > 9 && classes.countMedium)}
        >
          {cancelled}
        </div>
      </div>

      {/* {topay > 0 && <button className={classes.payall}>{t("Pay all invoices")}</button>} */}
    </div>
  );
};
