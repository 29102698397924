import React, { FC, useState } from 'react';
import { Modal } from '../../../components/Modal';
import { Button } from '../../../components/Button';
import { TFunction } from 'i18next';
import ReCAPTCHA from 'react-google-recaptcha';

import classes from './RestoreModal.module.scss';
import { OpenModalType } from '../types';
import MobileFilterModal from '../../../components/MobileFilterModal';

interface RestoreModalProps {
  title: string;
  description: string;
  openModal: OpenModalType;
  t: TFunction;
  setOpenModal: (open: OpenModalType) => void;
  setRecaptcha: (token: string) => void;
  handleRestore: () => void;
}

const RestoreModal: FC<RestoreModalProps> = ({
  title,
  description,
  t,
  openModal,
  setOpenModal,
  setRecaptcha,
  handleRestore,
}) => {
  const { desktop, mobile } = openModal;
  const [disabled, setDisabled] = useState(true);
  const [captcha, setCaptcha] = useState<ReCAPTCHA | null>();

  const modalContent = (
    <div className={classes.modal}>
      <div className={classes.modal__content}>
        <span className={classes.modal__title}>{title}</span>
        <span className={classes.modal__description}>{description}</span>
        <div className={classes.modal__captcha}>
          <ReCAPTCHA
            ref={(el) => setCaptcha(el)}
            sitekey={`${process.env.REACT_APP_GOOGLE_RECAPTHA_KEY}`}
            onChange={(token) => {
              if (token) {
                setRecaptcha(token);
                setDisabled(false);
              }
            }}
            hl="en"
            size="normal"
          />
        </div>
      </div>
      <div className={classes.modal__button_wrapper}>
        <Button
          className={classes.modal__button}
          onClick={() => {
            handleRestore();
            setOpenModal({ ...openModal, desktop: false, mobile: false });
            captcha?.reset();
          }}
          theme="primary"
          disabled={disabled}
        >
          {t('Resend email')}
        </Button>
      </div>
    </div>
  );

  return mobile ? (
    <MobileFilterModal
      title={title}
      titleClassName={classes.customTitle}
      onClose={() => setOpenModal({ ...openModal, mobile: false })}
      isOutsideBase
    >
      {modalContent}
    </MobileFilterModal>
  ) : (
    <Modal
      title=""
      isOpen={desktop}
      onClose={() => setOpenModal({ ...openModal, desktop: false })}
      isOutsideBase
    >
      {modalContent}
    </Modal>
  );
};

export default RestoreModal;
