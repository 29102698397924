import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import {
  RegistrationRuStepOneProps,
  registrationRuStepOneSchema,
} from '../../Account/validationSchemas';
import Service from 'services/service';
import { getLangName } from 'tools/utils';
import { ShowNotification } from 'tools/showNotification';

export const useRegistrationFormRu = (kidUserInfo: { [key: string]: any }) => {
  const { t, i18n } = useTranslation();
  const [schema, setSchema] = useState<any>(registrationRuStepOneSchema(t));
  const currentLanguage = localStorage.getItem('kitGlobalLng');
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setError,
    getValues,
    setValue,
    watch,
  } = useForm<RegistrationRuStepOneProps>({
    resolver: yupResolver(schema as any),
    mode: 'all',
  });

  const companySizes = [
    {
      id: 'MICRO',
      name: t('Micro Business'),
      description: t('1-10 employees'),
      size: 1,
    },
    {
      id: 'SMALL',
      name: t('Small Business'),
      description: t('11-50 employees'),
      size: 2,
    },
    {
      id: 'MEDIUM',
      name: t('Medium-Sized Business'),
      description: t('51-250 employees'),
      size: 3,
    },
    {
      id: 'LARGE',
      name: t('Large Business'),
      description: t('251-1000 employees'),
      size: 4,
    },
    {
      id: 'CORPORATION',
      name: t('Corporation'),
      description: t('more than 1000 employees'),
      size: 5,
    },
  ];

  useEffect(() => {
    if (kidUserInfo) {
      setValue('kid_token', kidUserInfo.kid_token);
    } else {
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    setSchema(registrationRuStepOneSchema(t));
  }, [currentLanguage]);

  const { companyName, url } = getValues() as any;
  const formRef = useRef(null);
  const [goals, setGoals] = useState<string[]>([]);
  const [comment, setComment] = useState<string>('');
  const [check, setCheck] = useState(false);
  // const [companySizes, setCompanySizes] = useState<any>([]);
  const [selectedCompanySize, setSelectedCompanySize] = useState<any>();
  const [businessTypes, setBusinessTypes] = useState<any>([]);
  const [selectedBusinessType, setSelectedBusinessType] = useState<any>();
  const [checkErrors, setCheckErrors] = useState(false);
  const [isStepOneValid, setIsStepOneValid] = useState(false);

  const setErrorMessage = (field: any, message: string) => {
    setError(field, { type: 'server', message });
  };

  useEffect(() => {
    const getBusinessTypes = async () => {
      try {
        const response = await Service.getBusinessTypes();

        const data = response?.data?.results?.map((item: any) => ({
          id: item.id,
          name: getLangName(item, 'name', i18n),
        }));
        setBusinessTypes(data);
      } catch (error) {
        setBusinessTypes([]);
      }
    };
    getBusinessTypes();
  }, []);

  const submit = useCallback(
    async (e: any) => {
      const formData = new FormData();

      setCheckErrors(isValid);

      if (kidUserInfo) {
        formData.append('kid_token', kidUserInfo.token);
        formData.append(
          'full_name',
          `${kidUserInfo.first_name} ${kidUserInfo.last_name}`
        );
        formData.append('email', kidUserInfo.email);
        formData.append('phone_number', kidUserInfo.phone);
      }
      if (selectedCompanySize)
        formData.append('company_size', selectedCompanySize?.id);
      if (selectedBusinessType)
        formData.append('company_industry', selectedBusinessType?.id);

      formData.append('source_page', window.location?.href);
      formData.append('language', i18n.language);
      if (goals.length > 0) {
        goals.map((goal) => {
          formData.append('goal', goal);
          return null;
        });
      }
      if (comment !== '') {
        formData.append('other_field_value', comment);
      }
      if (check) {
        formData.append('free_consultation', 'on');
      }
      if (companyName) {
        formData.append('company_name', companyName.trim());
      }
      if (url) {
        formData.append('company_link', url.trim());
      }

      const requestOptions = {
        method: 'POST',
        body: formData,
        headers: {
          'Platform-Type': process.env.REACT_APP_PLATFORM_TYPE || '',
        },
      };

      fetch(`${process.env.REACT_APP_API}/register/`, requestOptions)
        .then((response) => response.json())
        .then((response) => {
          const { form_errors, status, message } = response || {};

          if (form_errors || status === false) {
            ShowNotification({ type: 'error', children: t(message) });
            const { company_name, company_link } = form_errors || {};

            if (company_name) {
              setErrorMessage('companyName', company_name[0]);
            }
            if (company_link) {
              setErrorMessage('url', company_link[0]);
            }
          } else {
            window.location.href = `${process.env.REACT_APP_KID_URL}?site_id=${process.env.REACT_APP_KID_SITE_ID}&3`;
          }
        });
    },
    [
      formRef,
      companyName,
      url,
      goals,
      check,
      comment,
      selectedBusinessType,
      selectedCompanySize,
    ]
  );

  const [companyNameSelect, setcompanyNameSelect] = useState('');
  const [companyLink, setcompanyLink] = useState('');

  const isAgree = watch('iAgree');

  useEffect(() => {
    if (
      companyName !== '' &&
      companyLink !== '' &&
      isAgree &&
      selectedCompanySize &&
      selectedBusinessType
    ) {
      setIsStepOneValid(true);
    } else {
      setIsStepOneValid(false);
    }
  }, [
    isValid,
    companyName,
    companyLink,
    isAgree,
    selectedCompanySize,
    selectedBusinessType,
  ]);

  return {
    formRef,
    handleSubmit,
    submit,
    t,
    setcompanyNameSelect,
    selectedBusinessType,
    isStepOneValid,
    businessTypes,
    setSelectedBusinessType,
    i18n,
    setSelectedCompanySize,
    errors,
    control,
    setcompanyLink,
    companySizes,
    selectedCompanySize,
  };
};
