import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useUserStore } from '../contexts/UserContext';
import { toJS } from 'mobx';
import { LocalePage } from '../pages/LocalePage';
import { RUSSIAN_LOCALE } from '../utils';
import { ChangeUser } from '../api/changeUser';
import { useCookies } from 'react-cookie';

export const CheckLocal = () => {
  const userStore = useUserStore();
  const [cookies, setCookies] = useCookies(['access', 'refresh']);
  const [isUserReady, setIsUserReady] = useState(false);

  // TODO --- проверить условие после сливания правок с isConfirmLocation

  if (RUSSIAN_LOCALE) {
    try {
      const requestData = {
        language: 'ru',
        currency: { id: 7 },
      };
      ChangeUser({ access: cookies.access, data: requestData });
    } catch (error) {
      console.error(error);
    }

    return <Outlet />;
  }

  if (RUSSIAN_LOCALE) {
    return isUserReady && <Outlet />;
  } else if (
    toJS(userStore).user?.is_confirmed_local_preferences === false &&
    !RUSSIAN_LOCALE
  ) {
    return <LocalePage />;
  } else return <Outlet />;
};
