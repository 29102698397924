import { ServiceDetailContainer } from 'containers/ServiceDetail';
import { FC } from 'react';
import '../styles/main.scss';
import { useMarketPlaceStore } from '../contexts/marketPlaceContext';
import { NotFoundPage } from './NotFoundPage';

// todo add localization feature
export const ServiceDetail: FC = () => {
  const marketPlaceStore = useMarketPlaceStore();
  const { isErrorServices } = marketPlaceStore || {};
  return !isErrorServices ? <ServiceDetailContainer /> : <NotFoundPage />;
};
