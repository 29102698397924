import React, { ErrorInfo, FC } from 'react';
import classes from './ErrorPage.module.scss';
import plug from 'img/System/plug.png';
import { useTranslation } from 'react-i18next';
import { Button } from '../Button';
import { useAuthStore } from '../../contexts/AuthContext';

interface ErrorInfoProps {
  errorInfo: ErrorInfo | null;
  error: string | undefined;
}

interface ErrorPageProps {
  errorMessage?: string;
  title?: string;
  errorInfo?: ErrorInfoProps | null;
}

export const ErrorPageContainer: FC<ErrorPageProps> = ({
  errorMessage,
  title,
  errorInfo,
}) => {
  const { t } = useTranslation();
  const { isServerError } = useAuthStore();
  const { error, errorInfo: errorInfoData } = errorInfo || {};

  return (
    <div className={classes.wrap}>
      <div className={classes.inner}>
        <span className={classes.title}>{title}</span>
        <p className={classes.subtitle}>
          {errorMessage ||
            t(
              'Sorry, there are some technical issues. Please refresh the page or try again later'
            )}
        </p>
        {!isServerError && (
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {error && error}
            <br />
            {errorInfoData?.componentStack}
          </details>
        )}
        <Button
          theme="primary"
          className={classes.button}
          onClick={(e: any) => {
            e.preventDefault();
            !isServerError ? window.history.back() : window.location.reload();
          }}
        >
          {!isServerError ? t('Back') : t('Refresh')}
        </Button>
      </div>
      <div className={classes.img}>
        <img src={plug} alt="plug" />
      </div>
    </div>
  );
};
