import { AxiosResponse } from 'axios';
import api from './http';
import { QueryParamTypes } from '../constants/queryParamTypes';

export default class User {
  static async getUser(): Promise<AxiosResponse> {
    return api.get('/api/v1/users/');
  }

  static async sendRegistrationCompleteEmail(): Promise<AxiosResponse> {
    return api.post('/api/v1/auth/send_registration_complete_email/');
  }

  static async changeLanguage(language: string): Promise<AxiosResponse> {
    return api.put('/api/v1/users/', {
      language: language,
    });
  }

  static async setOnboardingStatus(
    onboarding_client_passed: boolean
  ): Promise<AxiosResponse> {
    return api.put('/api/v1/users/', { onboarding_client_passed });
  }

  static async getProjects(): Promise<AxiosResponse> {
    return api.get('/api/v1/projects/');
  }

  static async getProject(
    projectId: number
  ): Promise<AxiosResponse<any> | void> {
    return api
      .get(`api/v1/projects/${projectId}/`)
      .then((response) => response)
      .catch((error) => {
        if (error.response.status === 404) {
          return error?.response;
        }
      });
  }

  static async updateProject(projectId: number, goal_name: string) {
    return await api
      .post(`api/v1/projects/${projectId}/project_goal/`, {
        goal: { name: goal_name },
        project: projectId,
      })
      .then((response) => {
        if (response.status === 201) {
          return response?.data;
        }
      });
  }

  static async deleteGoal(projectId: number, goalId: number) {
    return await api
      .put(`api/v1/projects/${projectId}/project_goal/${goalId}/`)
      .then((response) => {
        if (response.status === 200) {
          return response?.data;
        }
      });
  }

  static async getInvoices(params?: QueryParamTypes): Promise<AxiosResponse> {
    const url = `/api/v1/finances/?${params || 'page=1'}`;
    return api.get(url);
  }

  static async getProjectInvoices(
    page = 1,
    projectId: number
  ): Promise<AxiosResponse> {
    const url = `/api/v1/projects/${projectId}/project_invoices/?page=${page}`;
    return api
      .get(url)
      .then((response) => response)
      .catch((error) => {
        if (error.response.status === 404) {
          return error?.response;
        }
      });
  }
}
