import classes from './LocalePageContainer.module.scss';

import { useState, useEffect, useCallback, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import { GetCountries } from 'api/getCountries';
import { GetCurrencies } from 'api/getCurrencies';
import { ChangeUser } from 'api/changeUser';
import { languages } from 'constants/languages';
import { LocalPreferencesForm } from 'components/Header/LocalPreferences';
import { useUserStore } from 'contexts/UserContext';
import { useAuthStore } from 'contexts/AuthContext';
import { ShowNotification } from 'tools/showNotification';

import { Steps } from 'intro.js-react';
import '../CardsContainer/IntroTooltip.css';
import 'intro.js/introjs.css';
import { localeMessages } from './hints';
import {
  isOnboardingActive,
  setOnboardingOptions,
} from '../../tools/onboarding';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import { LABELS } from '../../constants/onboarding';

export const LocalePageContainer = ({}) => {
  const userStore = useUserStore();
  const authStore = useAuthStore();
  const { currency, country } = userStore?.user;
  const [countries, setCountries] = useState<any>([]);
  const [currencies, setCurrencies] = useState<any>([]);
  const [userCountry, setUserCountry] = useState(country);
  const [userCurrency, setUserCurrency] = useState(currency);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const userName = userStore.user?.full_name;
  const isMobile = useWindowWidth().isSmallLaptop;
  const enableHints = isOnboardingActive(userStore?.pagesToOnboard?.locale);

  const handleExit = useCallback(() => {
    setOnboardingOptions({ locale: true });
    userStore.enableOnboarding(true);
  }, [userStore]);

  const { i18n, t } = useTranslation();

  const [cookies, setCookies] = useCookies(['access', 'refresh']);

  const { GOT_IT, NEXT, BACK } = LABELS(t);

  const getParams = useCallback(async () => {
    if (userStore.user) {
      setUserCountry(userStore.user.country);
      setUserCurrency(userStore.user.currency);
    }
  }, [userStore]);

  const getCountries = async () => {
    try {
      const response = await GetCountries({
        access: cookies.access,
        refresh: cookies.refresh,
      });
      setCountries(response);
    } catch (error) {
      setCountries([]);
    }
  };

  const getCurrencies = async () => {
    try {
      const response = await GetCurrencies({
        access: cookies.access,
        refresh: cookies.refresh,
      });
      setCurrencies(response);
    } catch (error) {
      setCurrencies([]);
    }
  };

  const handleSaveLocalPreferences = async (data: any) => {
    setIsDisabled(true);
    try {
      const response = await ChangeUser({ access: cookies.access, data: data });
      userStore.addUser(response.user);
      setUserCountry(response.user.country);
      setUserCurrency(response.user.currency);
      userStore.changeLanguage(response.user.language.lang_code);
      setIsSuccess(true);
      ShowNotification({
        type: 'success',
        children: t('Changes saved successfully'),
      });
      window.location.reload();
    } catch (error) {
      console.error(error);
      ShowNotification({
        type: 'error',
        children: t('An error occurred. Please try again later'),
      });
    }
    setIsDisabled(false);
  };

  useEffect(() => {
    if (authStore.isAuth) {
      getParams();
      getCountries();
      getCurrencies();
    }
  }, [userStore.user]);

  return (
    <div className={classes.container}>
      <Steps
        enabled={isMobile && enableHints}
        steps={localeMessages(userName, t)}
        options={{
          tooltipClass: 'customTooltip',
          doneLabel: GOT_IT,
          nextLabel: NEXT,
          prevLabel: BACK,
        }}
        initialStep={0}
        onComplete={handleExit}
        onExit={handleExit}
      />
      <h2 className={classes.title}>
        {t('Please, confirm your local preferences')}
      </h2>
      {userStore.user && (
        <div className={classes.preference}>
          <LocalPreferencesForm
            onClose={() => {}}
            onSave={handleSaveLocalPreferences}
            noticeText={t(
              'Local settings affect the interface language and prices you see on the platform'
            )}
            onlySaveButton={true}
            success={isSuccess}
            disabled={isDisabled}
            setIsDisabled={setIsDisabled}
            countries={countries}
            currencies={currencies}
            languages={languages}
            currentCountry={userCountry}
            currentCurrency={userCurrency}
            currentLanguage={languages.find(
              (item) => item?.lang_code === i18n.language
            )}
          ></LocalPreferencesForm>
        </div>
      )}
    </div>
  );
};
