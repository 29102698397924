import clsx from 'clsx';
import React, { FC } from 'react';
import classes from './User.module.scss';
import { UserGradientIcon } from 'components/Icons/UserGradientIcon';
import { useTranslation } from 'react-i18next';

export interface UserProps {
  img?: string;
  name?: string;
}
export const User: FC<UserProps> = ({ img, name }) => {
  const { t } = useTranslation();

  return (
    <span className={clsx(classes.wrap, 'user')}>
      <div
        className={classes.user}
        style={{ backgroundImage: img && `url(${img})` }}
      >
        {!img && <UserGradientIcon />}
      </div>
      <span className={classes.name}>{t('Account')}</span>
    </span>
  );
};
