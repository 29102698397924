import { FC, useCallback, useEffect, useState } from 'react';
import classes from './ProjectsDetail.module.scss';

import { PersonIcon } from 'components/Icons/PersonIcon';

import { NavLink } from 'react-router-dom';
import { TitleAndDescription } from 'components/TitleAndDescription/TitleAndDescription';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useUserStore } from 'contexts/UserContext';
import { SearchIcon } from 'components/Icons/SearchIcon';
import { ProjectsDealStagesContainer } from './DealStage';
import { ProjectGoalsContainer } from './Goals';
import { InvoiceCardContainer } from '../Finances/InvoiceCard';
import User from '../../services/user';
import { Pagination } from '../../components/Pagination';
import { Fader } from '../../components/Fader';
import FormatPrice from '../../components/FormatPriceAndCurrency';
import { getBillMessage, integerToWords } from '../../tools/numberToWords';
import ArrowUpRight from '../../components/Icons/ArrowUpRight';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import EmptyProducts from '../../components/EmptyProducts/EmptyProducts';
import clsx from 'clsx';
import { API_URL } from '../../services/http';
import i18n from 'i18n/i18n';
import { RUSSIAN_LOCALE } from 'utils';
import { toJS } from 'mobx';
import manager_ru from 'img/default_manager_image_ru.svg';

interface Props {}

export const ProjectsDetailContainer: FC<Props> = observer(({}) => {
  const userStore = useUserStore();
  const [tableActive, setTableActive] = useState(false);
  const [toPayCountMessage, setToPayCountMessage] = useState('');
  const { t } = useTranslation();
  const { isTablet } = useWindowWidth();
  const manager_img = RUSSIAN_LOCALE
    ? manager_ru
    : `${API_URL}/static/core/img/default_manager_image.png`;

  const tabid1 = 1;
  const tabid2 = 2;
  const tabid3 = 3;

  const tabs = [
    {
      id: tabid1,
      label: t('stages'),
    },
    {
      id: tabid2,
      label: t('goals'),
    },
    {
      id: tabid3,
      label: t('invoices'),
    },
  ];

  const [activeTab, setActivTab] = useState(tabs[0].id);
  const [project, setProject] = useState(userStore?.projectData);
  const projectName = project[`name_${i18n.language}`] ?? project?.name;
  const projectManager = project?.manager;
  const projectNumber = project?.number;
  const legalEntity = project?.legal_entity;
  const projectCost = project.cost[0].cost;
  const projectCurrencySymbol = project.currency?.symbol;
  // invoices
  const projectInvoices = userStore.projectInvoices;
  const toPayCount = projectInvoices?.results?.to_pay_count;
  const partiallyPaidAmountSum = projectInvoices.results.partially_paid_sum;
  const totalDue = projectInvoices.results?.total_sum - partiallyPaidAmountSum;
  const source =
    project?.source?.name &&
    (project?.source?.name?.includes('http')
      ? project?.source?.name
      : `https://${project?.source?.name}`);

  // Pagination
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(projectInvoices.count / itemsPerPage);

  const fetchPaginationData = useCallback(
    async (currentPage: number) => {
      setTableActive(true);
      try {
        setCurrentPage(currentPage);
        await userStore.loadProjectInvoices(currentPage, project.id);
      } catch (error) {
        console.error(error);
      } finally {
        setTableActive(false);
      }
    },
    [currentPage]
  );

  const updateProject = async () => {
    // use in ProjectGoalsContainer
    const response = await User.getProject(project.id);
    setProject(response?.data);

    return response;
  };

  useEffect(() => {
    updateProject();
  }, [userStore.user]);

  useEffect(() => {
    const countToWords = integerToWords(toPayCount, t);

    const message =
      Number(toPayCount) >= 1
        ? RUSSIAN_LOCALE
          ? `${countToWords} ${getBillMessage(toPayCount)}`
          : t('{{ name }} invoice is waiting for payment', {
              name: countToWords,
            })
        : t('No invoices to pay');

    setToPayCountMessage(message);
  }, [activeTab, toPayCount, t, userStore.user]);

  const formattedMessage =
    toPayCountMessage[0]?.toUpperCase() + toPayCountMessage?.slice(1);

  const tabsId = 'project_tabs';

  const shortProjectName =
    projectName?.length > 30 ? `${projectName?.slice(0, 30)}...` : projectName;
  const shortSource =
    source?.length > (isTablet ? 35 : 60)
      ? `${source?.slice(0, isTablet ? 35 : 60)}...`
      : source;

  return (
    <>
      <TitleAndDescription pageName="ProjectsDetail" title={projectName} />
      <div className={classes.wrapper}>
        <ul className={classes.breadcrumbs}>
          <li className={classes.breadcrumbs__item}>
            <NavLink to={`/projects/`} className={classes.breadcrumbs__link}>
              {t('Projects')}
            </NavLink>
          </li>
          <li className={classes.breadcrumbs__item}>
            <span className={classes.breadcrumbs__link}>
              {isTablet ? shortProjectName : projectName}
            </span>
          </li>
        </ul>

        <h1 className={classes.project__header}>{projectName}</h1>
        <div
          className={clsx(classes.project__link, !isTablet && classes.desktop)}
        >
          <div
            className={clsx(
              classes.project__id,
              source && classes.project__id__source
            )}
          >
            ID {projectNumber}
          </div>
          {source && (
            <NavLink to={source} target="_blank">
              {shortSource}
              <ArrowUpRight className={classes.arrowIcon} />
            </NavLink>
          )}
        </div>

        <div className={classes.grid}>
          <div className={classes.grid__main}>
            <div className={classes.project__tabs} id={tabsId}>
              {tabs.map((tab) => (
                <label key={tab.id} className={classes.project__tab}>
                  <input
                    type="radio"
                    name="project_tab"
                    id="stages"
                    onChange={() => {
                      setActivTab(tab.id);
                    }}
                    checked={activeTab === tab.id}
                  />
                  <div>
                    {tab.label}
                    {tab.id === tabid3 && (
                      <span>{projectInvoices.results.all_count}</span>
                    )}
                  </div>
                </label>
              ))}
            </div>

            {activeTab === tabid1 && (
              <ProjectsDealStagesContainer project={project} />
            )}

            {activeTab === tabid3 && (
              <div className={classes.invoices}>
                <div className={classes.invoices__cards}>
                  <div className={classes.invoices__card}>
                    <div className={classes.invoices__card__header}>
                      {t('Project cost')}
                    </div>
                    <div className={classes.invoices__card__price}>
                      <FormatPrice
                        currency={projectCurrencySymbol}
                        price={project?.solution?.is_free ? 0 : projectCost}
                        withMinus={false}
                      />
                    </div>
                  </div>

                  <div className={classes.invoices__card}>
                    <div className={classes.invoices__card__header}>
                      {t('Total due')}
                    </div>
                    <div className={classes.invoices__card__price}>
                      <FormatPrice
                        currency={projectCurrencySymbol}
                        price={totalDue}
                      />
                    </div>
                    <div className={classes.invoices__card__text}>
                      {formattedMessage}
                    </div>
                  </div>
                </div>
                {projectInvoices.results.sub_invoices.length ? (
                  <Fader active={tableActive}>
                    <div className={classes.invoices__table}>
                      <div className={classes.invoices__table__row}>
                        <div className={classes.invoices__table__cell}>
                          <button className={classes.invoices__table__search}>
                            <SearchIcon />
                          </button>
                        </div>

                        <div className={classes.invoices__table__cell}>
                          <span>{t('Invoices')}</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <path
                              d="M4 8H12M2 4H14M6 12H10"
                              stroke="#9C93AA"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>

                        <div className={classes.invoices__table__cell}>
                          <span>{t('Issued')}</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <path
                              d="M4 8H12M2 4H14M6 12H10"
                              stroke="#9C93AA"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>

                        <div className={classes.invoices__table__cell}>
                          <span>{t('Amount')}</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <path
                              d="M4 8H12M2 4H14M6 12H10"
                              stroke="#9C93AA"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>

                        <div className={classes.invoices__table__cell}>
                          <span>{t('Action')}</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <path
                              d="M4 8H12M2 4H14M6 12H10"
                              stroke="#9C93AA"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                      {projectInvoices.results.sub_invoices.map(
                        (invoice: any) => (
                          <InvoiceCardContainer
                            invoice={invoice}
                            className={classes.mini}
                          />
                        )
                      )}
                      {totalPages > 1 && (
                        <div className={classes.pagination}>
                          <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            setPageNumber={fetchPaginationData}
                          />
                        </div>
                      )}
                    </div>
                  </Fader>
                ) : (
                  <EmptyProducts
                    bottomText={t("You don't have any invoices")}
                  />
                )}
              </div>
            )}

            {activeTab === tabid2 && (
              <ProjectGoalsContainer
                project={project}
                updateProject={updateProject}
                tabsId={tabsId}
              />
            )}
          </div>
          <div className={classes.grid__side}>
            {legalEntity && (
              <div className={classes.grid__side__card}>
                <div className={classes.grid__side__card__top}>
                  <PersonIcon />
                  <div className={classes.grid__side__card__text}>
                    <h4 className={classes.grid__side__card__header}>
                      {legalEntity.name}
                    </h4>
                    <p className={classes.grid__side__card__par}>
                      {t('Legal entity')}
                    </p>
                  </div>
                </div>
              </div>
            )}
            <div className={classes.grid__side__card}>
              {projectManager ? (
                <div className={classes.grid__side__card__top}>
                  {!projectManager.image ? (
                    <img
                      src={manager_img}
                      alt=""
                      className={classes.card__grid__card__img}
                    />
                  ) : (
                    <img
                      src={projectManager.image}
                      alt=""
                      className={classes.card__grid__card__img}
                    />
                  )}
                  <div className={classes.grid__side__card__text}>
                    <h4 className={classes.grid__side__card__header_mini}>
                      {projectManager.name}
                    </h4>
                    <p className={classes.grid__side__card__par}>
                      {t('Your personal manager')}
                    </p>
                  </div>
                </div>
              ) : (
                <div className={classes.grid__side__card__top}>
                  <img
                    src={manager_img}
                    alt=""
                    className={classes.card__grid__card__img}
                  />
                  <div className={classes.no_manager}>
                    <p className={classes.no_manager_title}>
                      {t('Assigning manager')}
                    </p>
                    <p className={classes.no_manager_description}>
                      {t('Your personal manager')}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
